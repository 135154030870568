// TODO: Deprecate since we have the same thing in dcmjs?
const NUMBER = 'number';
const STRING = 'string';
const REGEX_TAG = /^x[0-9a-fx]{8}$/;

const DICOMTagDescriptions = Object.create(Object.prototype, {
  _descriptions: {
    configurable: false,
    enumerable: false,
    writable: false,
    value: Object.create(null),
  },
  tagNumberToString: {
    configurable: false,
    enumerable: true,
    writable: false,
    value: function tagNumberToString(tag) {
      let string; // by default, undefined is returned...
      if (this.isValidTagNumber(tag)) {
        // if it's a number, build its hexadecimal representation...
        string = 'x' + ('00000000' + tag.toString(16)).substr(-8);
      }
      return string;
    },
  },
  isValidTagNumber: {
    configurable: false,
    enumerable: true,
    writable: false,
    value: function isValidTagNumber(tag) {
      return typeof tag === NUMBER && tag >= 0 && tag <= 0xffffffff;
    },
  },
  isValidTag: {
    configurable: false,
    enumerable: true,
    writable: false,
    value: function isValidTag(tag) {
      return typeof tag === STRING
        ? REGEX_TAG.test(tag)
        : this.isValidTagNumber(tag);
    },
  },
  find: {
    configurable: false,
    enumerable: true,
    writable: false,
    value: function find(name) {
      let description; // by default, undefined is returned...
      if (typeof name !== STRING) {
        // if it's a number, a tag string will be returned...
        name = this.tagNumberToString(name);
      }
      if (typeof name === STRING) {
        description = this._descriptions[name];
      }
      return description;
    },
  },
  init: {
    configurable: false,
    enumerable: true,
    writable: false,
    value: function init(descriptionMap) {
      const _hasOwn = Object.prototype.hasOwnProperty;
      const _descriptions = this._descriptions;
      for (let tag in descriptionMap) {
        if (_hasOwn.call(descriptionMap, tag)) {
          if (!this.isValidTag(tag)) {
            // Skip in case tag is not valid...
            console.info(`DICOMTagDescriptions: Invalid tag "${tag}"...`);
            continue;
          }
          if (tag in _descriptions) {
            // Skip in case the tag is duplicated...
            console.info(`DICOMTagDescriptions: Duplicated tag "${tag}"...`);
            continue;
          }
          // Save keyword...
          const keyword = descriptionMap[tag];
          // Create a description entry and freeze it...
          const entry = Object.create(null);
          entry.tag = tag;
          entry.keyword = keyword;
          Object.freeze(entry);
          // Add tag references to entry...
          _descriptions[tag] = entry;
          // Add keyword references to entry (if not present already)...
          if (keyword in _descriptions) {
            const currentEntry = _descriptions[keyword];
            console.info(
              `DICOMTagDescriptions: Using <${currentEntry.tag},${
                currentEntry.keyword
              }> instead of <${entry.tag},${
                entry.keyword
              }> for keyword "${keyword}"...`
            );
          } else {
            _descriptions[keyword] = entry;
          }
        }
      }
      // Freeze internal description map...
      Object.freeze(_descriptions);
      // Freeze itself...
      Object.freeze(this);
    },
  },
});

/**
 * Map with DICOM Tag Descriptions
 */
let initialTagDescriptionMap = {
  x00020000: 'FileMetaInfoGroupLength',
  x00020001: 'FileMetaInfoVersion',
  x00020002: 'MediaStorageSOPClassUID',
  x00020003: 'MediaStorageSOPInstanceUID',
  x00020010: 'TransferSyntaxUID',
  x00020012: 'ImplementationClassUID',
  x00020013: 'ImplementationVersionName',
  x00020016: 'SourceApplicationEntityTitle',
  x00020100: 'PrivateInformationCreatorUID',
  x00020102: 'PrivateInformation',
  x00041130: 'FileSetID',
  x00041141: 'FileSetDescriptorFileID',
  x00041142: 'SpecificCharacterSetOfFile',
  x00041200: 'FirstDirectoryRecordOffset',
  x00041202: 'LastDirectoryRecordOffset',
  x00041212: 'FileSetConsistencyFlag',
  x00041220: 'DirectoryRecordSequence',
  x00041400: 'OffsetOfNextDirectoryRecord',
  x00041410: 'RecordInUseFlag',
  x00041420: 'LowerLevelDirectoryEntityOffset',
  x00041430: 'DirectoryRecordType',
  x00041432: 'PrivateRecordUID',
  x00041500: 'ReferencedFileID',
  x00041504: 'MRDRDirectoryRecordOffset',
  x00041510: 'ReferencedSOPClassUIDInFile',
  x00041511: 'ReferencedSOPInstanceUIDInFile',
  x00041512: 'ReferencedTransferSyntaxUIDInFile',
  x0004151a: 'ReferencedRelatedSOPClassUIDInFile',
  x00041600: 'NumberOfReferences',
  x00080000: 'IdentifyingGroupLength',
  x00080001: 'LengthToEnd',
  x00080005: 'SpecificCharacterSet',
  x00080006: 'LanguageCodeSequence',
  x00080008: 'ImageType',
  x00080010: 'RecognitionCode',
  x00080012: 'InstanceCreationDate',
  x00080013: 'InstanceCreationTime',
  x00080014: 'InstanceCreatorUID',
  x00080016: 'SOPClassUID',
  x00080018: 'SOPInstanceUID',
  x0008001a: 'RelatedGeneralSOPClassUID',
  x0008001b: 'OriginalSpecializedSOPClassUID',
  x00080020: 'StudyDate',
  x00080021: 'SeriesDate',
  x00080022: 'AcquisitionDate',
  x00080023: 'ContentDate',
  x00080024: 'OverlayDate',
  x00080025: 'CurveDate',
  x0008002a: 'AcquisitionDateTime',
  x00080030: 'StudyTime',
  x00080031: 'SeriesTime',
  x00080032: 'AcquisitionTime',
  x00080033: 'ContentTime',
  x00080034: 'OverlayTime',
  x00080035: 'CurveTime',
  x00080040: 'DataSetType',
  x00080041: 'DataSetSubtype',
  x00080042: 'NuclearMedicineSeriesType',
  x00080050: 'AccessionNumber',
  x00080052: 'QueryRetrieveLevel',
  x00080054: 'RetrieveAETitle',
  x00080056: 'InstanceAvailability',
  x00080058: 'FailedSOPInstanceUIDList',
  x00080060: 'Modality',
  x00080061: 'ModalitiesInStudy',
  x00080062: 'SOPClassesInStudy',
  x00080064: 'ConversionType',
  x00080068: 'PresentationIntentType',
  x00080070: 'Manufacturer',
  x00080080: 'InstitutionName',
  x00080081: 'InstitutionAddress',
  x00080082: 'InstitutionCodeSequence',
  x00080090: 'ReferringPhysicianName',
  x00080092: 'ReferringPhysicianAddress',
  x00080094: 'ReferringPhysicianTelephoneNumber',
  x00080096: 'ReferringPhysicianIDSequence',
  x00080100: 'CodeValue',
  x00080102: 'CodingSchemeDesignator',
  x00080103: 'CodingSchemeVersion',
  x00080104: 'CodeMeaning',
  x00080105: 'MappingResource',
  x00080106: 'ContextGroupVersion',
  x00080107: 'ContextGroupLocalVersion',
  x0008010b: 'ContextGroupExtensionFlag',
  x0008010c: 'CodingSchemeUID',
  x0008010d: 'ContextGroupExtensionCreatorUID',
  x0008010f: 'ContextIdentifier',
  x00080110: 'CodingSchemeIDSequence',
  x00080112: 'CodingSchemeRegistry',
  x00080114: 'CodingSchemeExternalID',
  x00080115: 'CodingSchemeName',
  x00080116: 'CodingSchemeResponsibleOrganization',
  x00080117: 'ContextUID',
  x00080201: 'TimezoneOffsetFromUTC',
  x00081000: 'NetworkID',
  x00081010: 'StationName',
  x00081030: 'StudyDescription',
  x00081032: 'ProcedureCodeSequence',
  x0008103e: 'SeriesDescription',
  x00081040: 'InstitutionalDepartmentName',
  x00081048: 'PhysiciansOfRecord',
  x00081049: 'PhysiciansOfRecordIDSequence',
  x00081050: 'PerformingPhysicianName',
  x00081052: 'PerformingPhysicianIDSequence',
  x00081060: 'NameOfPhysicianReadingStudy',
  x00081062: 'PhysicianReadingStudyIDSequence',
  x00081070: 'OperatorsName',
  x00081072: 'OperatorIDSequence',
  x00081080: 'AdmittingDiagnosesDescription',
  x00081084: 'AdmittingDiagnosesCodeSequence',
  x00081090: 'ManufacturersModelName',
  x00081100: 'ReferencedResultsSequence',
  x00081110: 'ReferencedStudySequence',
  x00081111: 'ReferencedPerformedProcedureStepSequence',
  x00081115: 'ReferencedSeriesSequence',
  x00081120: 'ReferencedPatientSequence',
  x00081125: 'ReferencedVisitSequence',
  x00081130: 'ReferencedOverlaySequence',
  x0008113a: 'ReferencedWaveformSequence',
  x00081140: 'ReferencedImageSequence',
  x00081145: 'ReferencedCurveSequence',
  x0008114a: 'ReferencedInstanceSequence',
  x00081150: 'ReferencedSOPClassUID',
  x00081155: 'ReferencedSOPInstanceUID',
  x0008115a: 'SOPClassesSupported',
  x00081160: 'ReferencedFrameNumber',
  x00081161: 'SimpleFrameList',
  x00081162: 'CalculatedFrameList',
  x00081163: 'TimeRange',
  x00081164: 'FrameExtractionSequence',
  x00081195: 'TransactionUID',
  x00081197: 'FailureReason',
  x00081198: 'FailedSOPSequence',
  x00081199: 'ReferencedSOPSequence',
  x00081200: 'OtherReferencedStudiesSequence',
  x00081250: 'RelatedSeriesSequence',
  x00082110: 'LossyImageCompressionRetired',
  x00082111: 'DerivationDescription',
  x00082112: 'SourceImageSequence',
  x00082120: 'StageName',
  x00082122: 'StageNumber',
  x00082124: 'NumberOfStages',
  x00082127: 'ViewName',
  x00082128: 'ViewNumber',
  x00082129: 'NumberOfEventTimers',
  x0008212a: 'NumberOfViewsInStage',
  x00082130: 'EventElapsedTimes',
  x00082132: 'EventTimerNames',
  x00082133: 'EventTimerSequence',
  x00082134: 'EventTimeOffset',
  x00082135: 'EventCodeSequence',
  x00082142: 'StartTrim',
  x00082143: 'StopTrim',
  x00082144: 'RecommendedDisplayFrameRate',
  x00082200: 'TransducerPosition',
  x00082204: 'TransducerOrientation',
  x00082208: 'AnatomicStructure',
  x00082218: 'AnatomicRegionSequence',
  x00082220: 'AnatomicRegionModifierSequence',
  x00082228: 'PrimaryAnatomicStructureSequence',
  x00082229: 'AnatomicStructureOrRegionSequence',
  x00082230: 'AnatomicStructureModifierSequence',
  x00082240: 'TransducerPositionSequence',
  x00082242: 'TransducerPositionModifierSequence',
  x00082244: 'TransducerOrientationSequence',
  x00082246: 'TransducerOrientationModifierSeq',
  x00082253: 'AnatomicEntrancePortalCodeSeqTrial',
  x00082255: 'AnatomicApproachDirCodeSeqTrial',
  x00082256: 'AnatomicPerspectiveDescrTrial',
  x00082257: 'AnatomicPerspectiveCodeSeqTrial',
  x00083001: 'AlternateRepresentationSequence',
  x00083010: 'IrradiationEventUID',
  x00084000: 'IdentifyingComments',
  x00089007: 'FrameType',
  x00089092: 'ReferencedImageEvidenceSequence',
  x00089121: 'ReferencedRawDataSequence',
  x00089123: 'CreatorVersionUID',
  x00089124: 'DerivationImageSequence',
  x00089154: 'SourceImageEvidenceSequence',
  x00089205: 'PixelPresentation',
  x00089206: 'VolumetricProperties',
  x00089207: 'VolumeBasedCalculationTechnique',
  x00089208: 'ComplexImageComponent',
  x00089209: 'AcquisitionContrast',
  x00089215: 'DerivationCodeSequence',
  x00089237: 'GrayscalePresentationStateSequence',
  x00089410: 'ReferencedOtherPlaneSequence',
  x00089458: 'FrameDisplaySequence',
  x00089459: 'RecommendedDisplayFrameRateInFloat',
  x00089460: 'SkipFrameRangeFlag',
  // x00091001: 'FullFidelity',
  // x00091002: 'SuiteID',
  // x00091004: 'ProductID',
  // x00091027: 'ImageActualDate',
  // x00091030: 'ServiceID',
  // x00091031: 'MobileLocationNumber',
  // x000910e3: 'EquipmentUID',
  // x000910e6: 'GenesisVersionNow',
  // x000910e7: 'ExamRecordChecksum',
  // x000910e9: 'ActualSeriesDataTimeStamp',
  x00100000: 'PatientGroupLength',
  x00100010: 'PatientName',
  x00100020: 'PatientID',
  x00100021: 'IssuerOfPatientID',
  x00100022: 'TypeOfPatientID',
  x00100030: 'PatientBirthDate',
  x00100032: 'PatientBirthTime',
  x00100040: 'PatientSex',
  x00100050: 'PatientInsurancePlanCodeSequence',
  x00100101: 'PatientPrimaryLanguageCodeSeq',
  x00100102: 'PatientPrimaryLanguageCodeModSeq',
  x00101000: 'OtherPatientIDs',
  x00101001: 'OtherPatientNames',
  x00101002: 'OtherPatientIDsSequence',
  x00101005: 'PatientBirthName',
  x00101010: 'PatientAge',
  x00101020: 'PatientSize',
  x00101030: 'PatientWeight',
  x00101040: 'PatientAddress',
  x00101050: 'InsurancePlanIdentification',
  x00101060: 'PatientMotherBirthName',
  x00101080: 'MilitaryRank',
  x00101081: 'BranchOfService',
  x00101090: 'MedicalRecordLocator',
  x00102000: 'MedicalAlerts',
  x00102110: 'Allergies',
  x00102150: 'CountryOfResidence',
  x00102152: 'RegionOfResidence',
  x00102154: 'PatientTelephoneNumbers',
  x00102160: 'EthnicGroup',
  x00102180: 'Occupation',
  x001021a0: 'SmokingStatus',
  x001021b0: 'AdditionalPatientHistory',
  x001021c0: 'PregnancyStatus',
  x001021d0: 'LastMenstrualDate',
  x001021f0: 'PatientReligiousPreference',
  x00102201: 'PatientSpeciesDescription',
  x00102202: 'PatientSpeciesCodeSequence',
  x00102203: 'PatientSexNeutered',
  x00102210: 'AnatomicalOrientationType',
  x00102292: 'PatientBreedDescription',
  x00102293: 'PatientBreedCodeSequence',
  x00102294: 'BreedRegistrationSequence',
  x00102295: 'BreedRegistrationNumber',
  x00102296: 'BreedRegistryCodeSequence',
  x00102297: 'ResponsiblePerson',
  x00102298: 'ResponsiblePersonRole',
  x00102299: 'ResponsibleOrganization',
  x00104000: 'PatientComments',
  x00109431: 'ExaminedBodyThickness',
  x00111010: 'PatientStatus',
  x00120010: 'ClinicalTrialSponsorName',
  x00120020: 'ClinicalTrialProtocolID',
  x00120021: 'ClinicalTrialProtocolName',
  x00120030: 'ClinicalTrialSiteID',
  x00120031: 'ClinicalTrialSiteName',
  x00120040: 'ClinicalTrialSubjectID',
  x00120042: 'ClinicalTrialSubjectReadingID',
  x00120050: 'ClinicalTrialTimePointID',
  x00120051: 'ClinicalTrialTimePointDescription',
  x00120060: 'ClinicalTrialCoordinatingCenter',
  x00120062: 'PatientIdentityRemoved',
  x00120063: 'DeidentificationMethod',
  x00120064: 'DeidentificationMethodCodeSequence',
  x00120071: 'ClinicalTrialSeriesID',
  x00120072: 'ClinicalTrialSeriesDescription',
  x00120084: 'DistributionType',
  x00120085: 'ConsentForDistributionFlag',
  x00180000: 'AcquisitionGroupLength',
  x00180010: 'ContrastBolusAgent',
  x00180012: 'ContrastBolusAgentSequence',
  x00180014: 'ContrastBolusAdministrationRoute',
  x00180015: 'BodyPartExamined',
  x00180020: 'ScanningSequence',
  x00180021: 'SequenceVariant',
  x00180022: 'ScanOptions',
  x00180023: 'MRAcquisitionType',
  x00180024: 'SequenceName',
  x00180025: 'AngioFlag',
  x00180026: 'InterventionDrugInformationSeq',
  x00180027: 'InterventionDrugStopTime',
  x00180028: 'InterventionDrugDose',
  x00180029: 'InterventionDrugSequence',
  x0018002a: 'AdditionalDrugSequence',
  x00180030: 'Radionuclide',
  x00180031: 'Radiopharmaceutical',
  x00180032: 'EnergyWindowCenterline',
  x00180033: 'EnergyWindowTotalWidth',
  x00180034: 'InterventionDrugName',
  x00180035: 'InterventionDrugStartTime',
  x00180036: 'InterventionSequence',
  x00180037: 'TherapyType',
  x00180038: 'InterventionStatus',
  x00180039: 'TherapyDescription',
  x0018003a: 'InterventionDescription',
  x00180040: 'CineRate',
  x00180042: 'InitialCineRunState',
  x00180050: 'SliceThickness',
  x00180060: 'KVP',
  x00180070: 'CountsAccumulated',
  x00180071: 'AcquisitionTerminationCondition',
  x00180072: 'EffectiveDuration',
  x00180073: 'AcquisitionStartCondition',
  x00180074: 'AcquisitionStartConditionData',
  x00180075: 'AcquisitionEndConditionData',
  x00180080: 'RepetitionTime',
  x00180081: 'EchoTime',
  x00180082: 'InversionTime',
  x00180083: 'NumberOfAverages',
  x00180084: 'ImagingFrequency',
  x00180085: 'ImagedNucleus',
  x00180086: 'EchoNumber',
  x00180087: 'MagneticFieldStrength',
  x00180088: 'SpacingBetweenSlices',
  x00180089: 'NumberOfPhaseEncodingSteps',
  x00180090: 'DataCollectionDiameter',
  x00180091: 'EchoTrainLength',
  x00180093: 'PercentSampling',
  x00180094: 'PercentPhaseFieldOfView',
  x00180095: 'PixelBandwidth',
  x00181000: 'DeviceSerialNumber',
  x00181002: 'DeviceUID',
  x00181003: 'DeviceID',
  x00181004: 'PlateID',
  x00181005: 'GeneratorID',
  x00181006: 'GridID',
  x00181007: 'CassetteID',
  x00181008: 'GantryID',
  x00181010: 'SecondaryCaptureDeviceID',
  x00181011: 'HardcopyCreationDeviceID',
  x00181012: 'DateOfSecondaryCapture',
  x00181014: 'TimeOfSecondaryCapture',
  x00181016: 'SecondaryCaptureDeviceManufacturer',
  x00181017: 'HardcopyDeviceManufacturer',
  x00181018: 'SecondaryCaptureDeviceModelName',
  x00181019: 'SecondaryCaptureDeviceSoftwareVers',
  x0018101a: 'HardcopyDeviceSoftwareVersion',
  x0018101b: 'HardcopyDeviceModelName',
  x00181020: 'SoftwareVersion',
  x00181022: 'VideoImageFormatAcquired',
  x00181023: 'DigitalImageFormatAcquired',
  x00181030: 'ProtocolName',
  x00181040: 'ContrastBolusRoute',
  x00181041: 'ContrastBolusVolume',
  x00181042: 'ContrastBolusStartTime',
  x00181043: 'ContrastBolusStopTime',
  x00181044: 'ContrastBolusTotalDose',
  x00181045: 'SyringeCounts',
  x00181046: 'ContrastFlowRate',
  x00181047: 'ContrastFlowDuration',
  x00181048: 'ContrastBolusIngredient',
  x00181049: 'ContrastBolusConcentration',
  x00181050: 'SpatialResolution',
  x00181060: 'TriggerTime',
  x00181061: 'TriggerSourceOrType',
  x00181062: 'NominalInterval',
  x00181063: 'FrameTime',
  x00181064: 'CardiacFramingType',
  x00181065: 'FrameTimeVector',
  x00181066: 'FrameDelay',
  x00181067: 'ImageTriggerDelay',
  x00181068: 'MultiplexGroupTimeOffset',
  x00181069: 'TriggerTimeOffset',
  x0018106a: 'SynchronizationTrigger',
  x0018106c: 'SynchronizationChannel',
  x0018106e: 'TriggerSamplePosition',
  x00181070: 'RadiopharmaceuticalRoute',
  x00181071: 'RadiopharmaceuticalVolume',
  x00181072: 'RadiopharmaceuticalStartTime',
  x00181073: 'RadiopharmaceuticalStopTime',
  x00181074: 'RadionuclideTotalDose',
  x00181075: 'RadionuclideHalfLife',
  x00181076: 'RadionuclidePositronFraction',
  x00181077: 'RadiopharmaceuticalSpecActivity',
  x00181078: 'RadiopharmaceuticalStartDateTime',
  x00181079: 'RadiopharmaceuticalStopDateTime',
  x00181080: 'BeatRejectionFlag',
  x00181081: 'LowRRValue',
  x00181082: 'HighRRValue',
  x00181083: 'IntervalsAcquired',
  x00181084: 'IntervalsRejected',
  x00181085: 'PVCRejection',
  x00181086: 'SkipBeats',
  x00181088: 'HeartRate',
  x00181090: 'CardiacNumberOfImages',
  x00181094: 'TriggerWindow',
  x00181100: 'ReconstructionDiameter',
  x00181110: 'DistanceSourceToDetector',
  x00181111: 'DistanceSourceToPatient',
  x00181114: 'EstimatedRadiographicMagnification',
  x00181120: 'GantryDetectorTilt',
  x00181121: 'GantryDetectorSlew',
  x00181130: 'TableHeight',
  x00181131: 'TableTraverse',
  x00181134: 'TableMotion',
  x00181135: 'TableVerticalIncrement',
  x00181136: 'TableLateralIncrement',
  x00181137: 'TableLongitudinalIncrement',
  x00181138: 'TableAngle',
  x0018113a: 'TableType',
  x00181140: 'RotationDirection',
  x00181141: 'AngularPosition',
  x00181142: 'RadialPosition',
  x00181143: 'ScanArc',
  x00181144: 'AngularStep',
  x00181145: 'CenterOfRotationOffset',
  x00181146: 'RotationOffset',
  x00181147: 'FieldOfViewShape',
  x00181149: 'FieldOfViewDimensions',
  x00181150: 'ExposureTime',
  x00181151: 'XRayTubeCurrent',
  x00181152: 'Exposure',
  x00181153: 'ExposureInMicroAmpSec',
  x00181154: 'AveragePulseWidth',
  x00181155: 'RadiationSetting',
  x00181156: 'RectificationType',
  x0018115a: 'RadiationMode',
  x0018115e: 'ImageAreaDoseProduct',
  x00181160: 'FilterType',
  x00181161: 'TypeOfFilters',
  x00181162: 'IntensifierSize',
  x00181164: 'ImagerPixelSpacing',
  x00181166: 'Grid',
  x00181170: 'GeneratorPower',
  x00181180: 'CollimatorGridName',
  x00181181: 'CollimatorType',
  x00181182: 'FocalDistance',
  x00181183: 'XFocusCenter',
  x00181184: 'YFocusCenter',
  x00181190: 'FocalSpots',
  x00181191: 'AnodeTargetMaterial',
  x001811a0: 'BodyPartThickness',
  x001811a2: 'CompressionForce',
  x00181200: 'DateOfLastCalibration',
  x00181201: 'TimeOfLastCalibration',
  x00181210: 'ConvolutionKernel',
  x00181240: 'UpperLowerPixelValues',
  x00181242: 'ActualFrameDuration',
  x00181243: 'CountRate',
  x00181244: 'PreferredPlaybackSequencing',
  x00181250: 'ReceiveCoilName',
  x00181251: 'TransmitCoilName',
  x00181260: 'PlateType',
  x00181261: 'PhosphorType',
  x00181300: 'ScanVelocity',
  x00181301: 'WholeBodyTechnique',
  x00181302: 'ScanLength',
  x00181310: 'AcquisitionMatrix',
  x00181312: 'InPlanePhaseEncodingDirection',
  x00181314: 'FlipAngle',
  x00181315: 'VariableFlipAngleFlag',
  x00181316: 'SAR',
  x00181318: 'DB-Dt',
  x00181400: 'AcquisitionDeviceProcessingDescr',
  x00181401: 'AcquisitionDeviceProcessingCode',
  x00181402: 'CassetteOrientation',
  x00181403: 'CassetteSize',
  x00181404: 'ExposuresOnPlate',
  x00181405: 'RelativeXRayExposure',
  x00181450: 'ColumnAngulation',
  x00181460: 'TomoLayerHeight',
  x00181470: 'TomoAngle',
  x00181480: 'TomoTime',
  x00181490: 'TomoType',
  x00181491: 'TomoClass',
  x00181495: 'NumberOfTomosynthesisSourceImages',
  x00181500: 'PositionerMotion',
  x00181508: 'PositionerType',
  x00181510: 'PositionerPrimaryAngle',
  x00181511: 'PositionerSecondaryAngle',
  x00181520: 'PositionerPrimaryAngleIncrement',
  x00181521: 'PositionerSecondaryAngleIncrement',
  x00181530: 'DetectorPrimaryAngle',
  x00181531: 'DetectorSecondaryAngle',
  x00181600: 'ShutterShape',
  x00181602: 'ShutterLeftVerticalEdge',
  x00181604: 'ShutterRightVerticalEdge',
  x00181606: 'ShutterUpperHorizontalEdge',
  x00181608: 'ShutterLowerHorizontalEdge',
  x00181610: 'CenterOfCircularShutter',
  x00181612: 'RadiusOfCircularShutter',
  x00181620: 'VerticesOfPolygonalShutter',
  x00181622: 'ShutterPresentationValue',
  x00181623: 'ShutterOverlayGroup',
  x00181624: 'ShutterPresentationColorCIELabVal',
  x00181700: 'CollimatorShape',
  x00181702: 'CollimatorLeftVerticalEdge',
  x00181704: 'CollimatorRightVerticalEdge',
  x00181706: 'CollimatorUpperHorizontalEdge',
  x00181708: 'CollimatorLowerHorizontalEdge',
  x00181710: 'CenterOfCircularCollimator',
  x00181712: 'RadiusOfCircularCollimator',
  x00181720: 'VerticesOfPolygonalCollimator',
  x00181800: 'AcquisitionTimeSynchronized',
  x00181801: 'TimeSource',
  x00181802: 'TimeDistributionProtocol',
  x00181803: 'NTPSourceAddress',
  x00182001: 'PageNumberVector',
  x00182002: 'FrameLabelVector',
  x00182003: 'FramePrimaryAngleVector',
  x00182004: 'FrameSecondaryAngleVector',
  x00182005: 'SliceLocationVector',
  x00182006: 'DisplayWindowLabelVector',
  x00182010: 'NominalScannedPixelSpacing',
  x00182020: 'DigitizingDeviceTransportDirection',
  x00182030: 'RotationOfScannedFilm',
  x00183100: 'IVUSAcquisition',
  x00183101: 'IVUSPullbackRate',
  x00183102: 'IVUSGatedRate',
  x00183103: 'IVUSPullbackStartFrameNumber',
  x00183104: 'IVUSPullbackStopFrameNumber',
  x00183105: 'LesionNumber',
  x00184000: 'AcquisitionComments',
  x00185000: 'OutputPower',
  x00185010: 'TransducerData',
  x00185012: 'FocusDepth',
  x00185020: 'ProcessingFunction',
  x00185021: 'PostprocessingFunction',
  x00185022: 'MechanicalIndex',
  x00185024: 'BoneThermalIndex',
  x00185026: 'CranialThermalIndex',
  x00185027: 'SoftTissueThermalIndex',
  x00185028: 'SoftTissueFocusThermalIndex',
  x00185029: 'SoftTissueSurfaceThermalIndex',
  x00185030: 'DynamicRange',
  x00185040: 'TotalGain',
  x00185050: 'DepthOfScanField',
  x00185100: 'PatientPosition',
  x00185101: 'ViewPosition',
  x00185104: 'ProjectionEponymousNameCodeSeq',
  x00185210: 'ImageTransformationMatrix',
  x00185212: 'ImageTranslationVector',
  x00186000: 'Sensitivity',
  x00186011: 'SequenceOfUltrasoundRegions',
  x00186012: 'RegionSpatialFormat',
  x00186014: 'RegionDataType',
  x00186016: 'RegionFlags',
  x00186018: 'RegionLocationMinX0',
  x0018601a: 'RegionLocationMinY0',
  x0018601c: 'RegionLocationMaxX1',
  x0018601e: 'RegionLocationMaxY1',
  x00186020: 'ReferencePixelX0',
  x00186022: 'ReferencePixelY0',
  x00186024: 'PhysicalUnitsXDirection',
  x00186026: 'PhysicalUnitsYDirection',
  x00186028: 'ReferencePixelPhysicalValueX',
  x0018602a: 'ReferencePixelPhysicalValueY',
  x0018602c: 'PhysicalDeltaX',
  x0018602e: 'PhysicalDeltaY',
  x00186030: 'TransducerFrequency',
  x00186031: 'TransducerType',
  x00186032: 'PulseRepetitionFrequency',
  x00186034: 'DopplerCorrectionAngle',
  x00186036: 'SteeringAngle',
  x00186038: 'DopplerSampleVolumeXPosRetired',
  x00186039: 'DopplerSampleVolumeXPosition',
  x0018603a: 'DopplerSampleVolumeYPosRetired',
  x0018603b: 'DopplerSampleVolumeYPosition',
  x0018603c: 'TMLinePositionX0Retired',
  x0018603d: 'TMLinePositionX0',
  x0018603e: 'TMLinePositionY0Retired',
  x0018603f: 'TMLinePositionY0',
  x00186040: 'TMLinePositionX1Retired',
  x00186041: 'TMLinePositionX1',
  x00186042: 'TMLinePositionY1Retired',
  x00186043: 'TMLinePositionY1',
  x00186044: 'PixelComponentOrganization',
  x00186046: 'PixelComponentMask',
  x00186048: 'PixelComponentRangeStart',
  x0018604a: 'PixelComponentRangeStop',
  x0018604c: 'PixelComponentPhysicalUnits',
  x0018604e: 'PixelComponentDataType',
  x00186050: 'NumberOfTableBreakPoints',
  x00186052: 'TableOfXBreakPoints',
  x00186054: 'TableOfYBreakPoints',
  x00186056: 'NumberOfTableEntries',
  x00186058: 'TableOfPixelValues',
  x0018605a: 'TableOfParameterValues',
  x00186060: 'RWaveTimeVector',
  x00187000: 'DetectorConditionsNominalFlag',
  x00187001: 'DetectorTemperature',
  x00187004: 'DetectorType',
  x00187005: 'DetectorConfiguration',
  x00187006: 'DetectorDescription',
  x00187008: 'DetectorMode',
  x0018700a: 'DetectorID',
  x0018700c: 'DateOfLastDetectorCalibration',
  x0018700e: 'TimeOfLastDetectorCalibration',
  x00187010: 'DetectorExposuresSinceCalibration',
  x00187011: 'DetectorExposuresSinceManufactured',
  x00187012: 'DetectorTimeSinceLastExposure',
  x00187014: 'DetectorActiveTime',
  x00187016: 'DetectorActiveOffsetFromExposure',
  x0018701a: 'DetectorBinning',
  x00187020: 'DetectorElementPhysicalSize',
  x00187022: 'DetectorElementSpacing',
  x00187024: 'DetectorActiveShape',
  x00187026: 'DetectorActiveDimensions',
  x00187028: 'DetectorActiveOrigin',
  x0018702a: 'DetectorManufacturerName',
  x0018702b: 'DetectorManufacturersModelName',
  x00187030: 'FieldOfViewOrigin',
  x00187032: 'FieldOfViewRotation',
  x00187034: 'FieldOfViewHorizontalFlip',
  x00187040: 'GridAbsorbingMaterial',
  x00187041: 'GridSpacingMaterial',
  x00187042: 'GridThickness',
  x00187044: 'GridPitch',
  x00187046: 'GridAspectRatio',
  x00187048: 'GridPeriod',
  x0018704c: 'GridFocalDistance',
  x00187050: 'FilterMaterial',
  x00187052: 'FilterThicknessMinimum',
  x00187054: 'FilterThicknessMaximum',
  x00187060: 'ExposureControlMode',
  x00187062: 'ExposureControlModeDescription',
  x00187064: 'ExposureStatus',
  x00187065: 'PhototimerSetting',
  x00188150: 'ExposureTimeInMicroSec',
  x00188151: 'XRayTubeCurrentInMicroAmps',
  x00189004: 'ContentQualification',
  x00189005: 'PulseSequenceName',
  x00189006: 'MRImagingModifierSequence',
  x00189008: 'EchoPulseSequence',
  x00189009: 'InversionRecovery',
  x00189010: 'FlowCompensation',
  x00189011: 'MultipleSpinEcho',
  x00189012: 'MultiPlanarExcitation',
  x00189014: 'PhaseContrast',
  x00189015: 'TimeOfFlightContrast',
  x00189016: 'Spoiling',
  x00189017: 'SteadyStatePulseSequence',
  x00189018: 'EchoPlanarPulseSequence',
  x00189019: 'TagAngleFirstAxis',
  x00189020: 'MagnetizationTransfer',
  x00189021: 'T2Preparation',
  x00189022: 'BloodSignalNulling',
  x00189024: 'SaturationRecovery',
  x00189025: 'SpectrallySelectedSuppression',
  x00189026: 'SpectrallySelectedExcitation',
  x00189027: 'SpatialPresaturation',
  x00189028: 'Tagging',
  x00189029: 'OversamplingPhase',
  x00189030: 'TagSpacingFirstDimension',
  x00189032: 'GeometryOfKSpaceTraversal',
  x00189033: 'SegmentedKSpaceTraversal',
  x00189034: 'RectilinearPhaseEncodeReordering',
  x00189035: 'TagThickness',
  x00189036: 'PartialFourierDirection',
  x00189037: 'CardiacSynchronizationTechnique',
  x00189041: 'ReceiveCoilManufacturerName',
  x00189042: 'MRReceiveCoilSequence',
  x00189043: 'ReceiveCoilType',
  x00189044: 'QuadratureReceiveCoil',
  x00189045: 'MultiCoilDefinitionSequence',
  x00189046: 'MultiCoilConfiguration',
  x00189047: 'MultiCoilElementName',
  x00189048: 'MultiCoilElementUsed',
  x00189049: 'MRTransmitCoilSequence',
  x00189050: 'TransmitCoilManufacturerName',
  x00189051: 'TransmitCoilType',
  x00189052: 'SpectralWidth',
  x00189053: 'ChemicalShiftReference',
  x00189054: 'VolumeLocalizationTechnique',
  x00189058: 'MRAcquisitionFrequencyEncodeSteps',
  x00189059: 'Decoupling',
  x00189060: 'DecoupledNucleus',
  x00189061: 'DecouplingFrequency',
  x00189062: 'DecouplingMethod',
  x00189063: 'DecouplingChemicalShiftReference',
  x00189064: 'KSpaceFiltering',
  x00189065: 'TimeDomainFiltering',
  x00189066: 'NumberOfZeroFills',
  x00189067: 'BaselineCorrection',
  x00189069: 'ParallelReductionFactorInPlane',
  x00189070: 'CardiacRRIntervalSpecified',
  x00189073: 'AcquisitionDuration',
  x00189074: 'FrameAcquisitionDateTime',
  x00189075: 'DiffusionDirectionality',
  x00189076: 'DiffusionGradientDirectionSequence',
  x00189077: 'ParallelAcquisition',
  x00189078: 'ParallelAcquisitionTechnique',
  x00189079: 'InversionTimes',
  x00189080: 'MetaboliteMapDescription',
  x00189081: 'PartialFourier',
  x00189082: 'EffectiveEchoTime',
  x00189083: 'MetaboliteMapCodeSequence',
  x00189084: 'ChemicalShiftSequence',
  x00189085: 'CardiacSignalSource',
  x00189087: 'DiffusionBValue',
  x00189089: 'DiffusionGradientOrientation',
  x00189090: 'VelocityEncodingDirection',
  x00189091: 'VelocityEncodingMinimumValue',
  x00189093: 'NumberOfKSpaceTrajectories',
  x00189094: 'CoverageOfKSpace',
  x00189095: 'SpectroscopyAcquisitionPhaseRows',
  x00189096: 'ParallelReductFactorInPlaneRetired',
  x00189098: 'TransmitterFrequency',
  x00189100: 'ResonantNucleus',
  x00189101: 'FrequencyCorrection',
  x00189103: 'MRSpectroscopyFOV-GeometrySequence',
  x00189104: 'SlabThickness',
  x00189105: 'SlabOrientation',
  x00189106: 'MidSlabPosition',
  x00189107: 'MRSpatialSaturationSequence',
  x00189112: 'MRTimingAndRelatedParametersSeq',
  x00189114: 'MREchoSequence',
  x00189115: 'MRModifierSequence',
  x00189117: 'MRDiffusionSequence',
  x00189118: 'CardiacTriggerSequence',
  x00189119: 'MRAveragesSequence',
  x00189125: 'MRFOV-GeometrySequence',
  x00189126: 'VolumeLocalizationSequence',
  x00189127: 'SpectroscopyAcquisitionDataColumns',
  x00189147: 'DiffusionAnisotropyType',
  x00189151: 'FrameReferenceDateTime',
  x00189152: 'MRMetaboliteMapSequence',
  x00189155: 'ParallelReductionFactorOutOfPlane',
  x00189159: 'SpectroscopyOutOfPlanePhaseSteps',
  x00189166: 'BulkMotionStatus',
  x00189168: 'ParallelReductionFactSecondInPlane',
  x00189169: 'CardiacBeatRejectionTechnique',
  x00189170: 'RespiratoryMotionCompTechnique',
  x00189171: 'RespiratorySignalSource',
  x00189172: 'BulkMotionCompensationTechnique',
  x00189173: 'BulkMotionSignalSource',
  x00189174: 'ApplicableSafetyStandardAgency',
  x00189175: 'ApplicableSafetyStandardDescr',
  x00189176: 'OperatingModeSequence',
  x00189177: 'OperatingModeType',
  x00189178: 'OperatingMode',
  x00189179: 'SpecificAbsorptionRateDefinition',
  x00189180: 'GradientOutputType',
  x00189181: 'SpecificAbsorptionRateValue',
  x00189182: 'GradientOutput',
  x00189183: 'FlowCompensationDirection',
  x00189184: 'TaggingDelay',
  x00189185: 'RespiratoryMotionCompTechDescr',
  x00189186: 'RespiratorySignalSourceID',
  x00189195: 'ChemicalShiftsMinIntegrateLimitHz',
  x00189196: 'ChemicalShiftsMaxIntegrateLimitHz',
  x00189197: 'MRVelocityEncodingSequence',
  x00189198: 'FirstOrderPhaseCorrection',
  x00189199: 'WaterReferencedPhaseCorrection',
  x00189200: 'MRSpectroscopyAcquisitionType',
  x00189214: 'RespiratoryCyclePosition',
  x00189217: 'VelocityEncodingMaximumValue',
  x00189218: 'TagSpacingSecondDimension',
  x00189219: 'TagAngleSecondAxis',
  x00189220: 'FrameAcquisitionDuration',
  x00189226: 'MRImageFrameTypeSequence',
  x00189227: 'MRSpectroscopyFrameTypeSequence',
  x00189231: 'MRAcqPhaseEncodingStepsInPlane',
  x00189232: 'MRAcqPhaseEncodingStepsOutOfPlane',
  x00189234: 'SpectroscopyAcqPhaseColumns',
  x00189236: 'CardiacCyclePosition',
  x00189239: 'SpecificAbsorptionRateSequence',
  x00189240: 'RFEchoTrainLength',
  x00189241: 'GradientEchoTrainLength',
  x00189295: 'ChemicalShiftsMinIntegrateLimitPPM',
  x00189296: 'ChemicalShiftsMaxIntegrateLimitPPM',
  x00189301: 'CTAcquisitionTypeSequence',
  x00189302: 'AcquisitionType',
  x00189303: 'TubeAngle',
  x00189304: 'CTAcquisitionDetailsSequence',
  x00189305: 'RevolutionTime',
  x00189306: 'SingleCollimationWidth',
  x00189307: 'TotalCollimationWidth',
  x00189308: 'CTTableDynamicsSequence',
  x00189309: 'TableSpeed',
  x00189310: 'TableFeedPerRotation',
  x00189311: 'SpiralPitchFactor',
  x00189312: 'CTGeometrySequence',
  x00189313: 'DataCollectionCenterPatient',
  x00189314: 'CTReconstructionSequence',
  x00189315: 'ReconstructionAlgorithm',
  x00189316: 'ConvolutionKernelGroup',
  x00189317: 'ReconstructionFieldOfView',
  x00189318: 'ReconstructionTargetCenterPatient',
  x00189319: 'ReconstructionAngle',
  x00189320: 'ImageFilter',
  x00189321: 'CTExposureSequence',
  x00189322: 'ReconstructionPixelSpacing',
  x00189323: 'ExposureModulationType',
  x00189324: 'EstimatedDoseSaving',
  x00189325: 'CTXRayDetailsSequence',
  x00189326: 'CTPositionSequence',
  x00189327: 'TablePosition',
  x00189328: 'ExposureTimeInMilliSec',
  x00189329: 'CTImageFrameTypeSequence',
  x00189330: 'XRayTubeCurrentInMilliAmps',
  x00189332: 'ExposureInMilliAmpSec',
  x00189333: 'ConstantVolumeFlag',
  x00189334: 'FluoroscopyFlag',
  x00189335: 'SourceToDataCollectionCenterDist',
  x00189337: 'ContrastBolusAgentNumber',
  x00189338: 'ContrastBolusIngredientCodeSeq',
  x00189340: 'ContrastAdministrationProfileSeq',
  x00189341: 'ContrastBolusUsageSequence',
  x00189342: 'ContrastBolusAgentAdministered',
  x00189343: 'ContrastBolusAgentDetected',
  x00189344: 'ContrastBolusAgentPhase',
  x00189345: 'CTDIvol',
  x00189346: 'CTDIPhantomTypeCodeSequence',
  x00189351: 'CalciumScoringMassFactorPatient',
  x00189352: 'CalciumScoringMassFactorDevice',
  x00189353: 'EnergyWeightingFactor',
  x00189360: 'CTAdditionalXRaySourceSequence',
  x00189401: 'ProjectionPixelCalibrationSequence',
  x00189402: 'DistanceSourceToIsocenter',
  x00189403: 'DistanceObjectToTableTop',
  x00189404: 'ObjectPixelSpacingInCenterOfBeam',
  x00189405: 'PositionerPositionSequence',
  x00189406: 'TablePositionSequence',
  x00189407: 'CollimatorShapeSequence',
  x00189412: 'XA-XRFFrameCharacteristicsSequence',
  x00189417: 'FrameAcquisitionSequence',
  x00189420: 'XRayReceptorType',
  x00189423: 'AcquisitionProtocolName',
  x00189424: 'AcquisitionProtocolDescription',
  x00189425: 'ContrastBolusIngredientOpaque',
  x00189426: 'DistanceReceptorPlaneToDetHousing',
  x00189427: 'IntensifierActiveShape',
  x00189428: 'IntensifierActiveDimensions',
  x00189429: 'PhysicalDetectorSize',
  x00189430: 'PositionOfIsocenterProjection',
  x00189432: 'FieldOfViewSequence',
  x00189433: 'FieldOfViewDescription',
  x00189434: 'ExposureControlSensingRegionsSeq',
  x00189435: 'ExposureControlSensingRegionShape',
  x00189436: 'ExposureControlSensRegionLeftEdge',
  x00189437: 'ExposureControlSensRegionRightEdge',
  x00189440: 'CenterOfCircExposControlSensRegion',
  x00189441: 'RadiusOfCircExposControlSensRegion',
  x00189447: 'ColumnAngulationPatient',
  x00189449: 'BeamAngle',
  x00189451: 'FrameDetectorParametersSequence',
  x00189452: 'CalculatedAnatomyThickness',
  x00189455: 'CalibrationSequence',
  x00189456: 'ObjectThicknessSequence',
  x00189457: 'PlaneIdentification',
  x00189461: 'FieldOfViewDimensionsInFloat',
  x00189462: 'IsocenterReferenceSystemSequence',
  x00189463: 'PositionerIsocenterPrimaryAngle',
  x00189464: 'PositionerIsocenterSecondaryAngle',
  x00189465: 'PositionerIsocenterDetRotAngle',
  x00189466: 'TableXPositionToIsocenter',
  x00189467: 'TableYPositionToIsocenter',
  x00189468: 'TableZPositionToIsocenter',
  x00189469: 'TableHorizontalRotationAngle',
  x00189470: 'TableHeadTiltAngle',
  x00189471: 'TableCradleTiltAngle',
  x00189472: 'FrameDisplayShutterSequence',
  x00189473: 'AcquiredImageAreaDoseProduct',
  x00189474: 'CArmPositionerTabletopRelationship',
  x00189476: 'XRayGeometrySequence',
  x00189477: 'IrradiationEventIDSequence',
  x00189504: 'XRay3DFrameTypeSequence',
  x00189506: 'ContributingSourcesSequence',
  x00189507: 'XRay3DAcquisitionSequence',
  x00189508: 'PrimaryPositionerScanArc',
  x00189509: 'SecondaryPositionerScanArc',
  x00189510: 'PrimaryPositionerScanStartAngle',
  x00189511: 'SecondaryPositionerScanStartAngle',
  x00189514: 'PrimaryPositionerIncrement',
  x00189515: 'SecondaryPositionerIncrement',
  x00189516: 'StartAcquisitionDateTime',
  x00189517: 'EndAcquisitionDateTime',
  x00189524: 'ApplicationName',
  x00189525: 'ApplicationVersion',
  x00189526: 'ApplicationManufacturer',
  x00189527: 'AlgorithmType',
  x00189528: 'AlgorithmDescription',
  x00189530: 'XRay3DReconstructionSequence',
  x00189531: 'ReconstructionDescription',
  x00189538: 'PerProjectionAcquisitionSequence',
  x00189601: 'DiffusionBMatrixSequence',
  x00189602: 'DiffusionBValueXX',
  x00189603: 'DiffusionBValueXY',
  x00189604: 'DiffusionBValueXZ',
  x00189605: 'DiffusionBValueYY',
  x00189606: 'DiffusionBValueYZ',
  x00189607: 'DiffusionBValueZZ',
  x00189701: 'DecayCorrectionDateTime',
  x00189715: 'StartDensityThreshold',
  x00189722: 'TerminationTimeThreshold',
  x00189725: 'DetectorGeometry',
  x00189727: 'AxialDetectorDimension',
  x00189735: 'PETPositionSequence',
  x00189739: 'NumberOfIterations',
  x00189740: 'NumberOfSubsets',
  x00189751: 'PETFrameTypeSequence',
  x00189756: 'ReconstructionType',
  x00189758: 'DecayCorrected',
  x00189759: 'AttenuationCorrected',
  x00189760: 'ScatterCorrected',
  x00189761: 'DeadTimeCorrected',
  x00189762: 'GantryMotionCorrected',
  x00189763: 'PatientMotionCorrected',
  x00189765: 'RandomsCorrected',
  x00189767: 'SensitivityCalibrated',
  x00189801: 'DepthsOfFocus',
  x00189804: 'ExclusionStartDatetime',
  x00189805: 'ExclusionDuration',
  x00189807: 'ImageDataTypeSequence',
  x00189808: 'DataType',
  x0018980b: 'AliasedDataType',
  x0018a001: 'ContributingEquipmentSequence',
  x0018a002: 'ContributionDateTime',
  x0018a003: 'ContributionDescription',
  // x00191002: 'NumberOfCellsIInDetector',
  // x00191003: 'CellNumberAtTheta',
  // x00191004: 'CellSpacing',
  // x0019100f: 'HorizFrameOfRef',
  // x00191011: 'SeriesContrast',
  // x00191012: 'LastPseq',
  // x00191013: 'StartNumberForBaseline',
  // x00191014: 'EndNumberForBaseline',
  // x00191015: 'StartNumberForEnhancedScans',
  // x00191016: 'EndNumberForEnhancedScans',
  // x00191017: 'SeriesPlane',
  // x00191018: 'FirstScanRas',
  // x00191019: 'FirstScanLocation',
  // x0019101a: 'LastScanRas',
  // x0019101b: 'LastScanLoc',
  // x0019101e: 'DisplayFieldOfView',
  // x00191023: 'TableSpeed',
  // x00191024: 'MidScanTime',
  // x00191025: 'MidScanFlag',
  // x00191026: 'DegreesOfAzimuth',
  // x00191027: 'GantryPeriod',
  // x0019102a: 'XRayOnPosition',
  // x0019102b: 'XRayOffPosition',
  // x0019102c: 'NumberOfTriggers',
  // x0019102e: 'AngleOfFirstView',
  // x0019102f: 'TriggerFrequency',
  // x00191039: 'ScanFOVType',
  // x00191040: 'StatReconFlag',
  // x00191041: 'ComputeType',
  // x00191042: 'SegmentNumber',
  // x00191043: 'TotalSegmentsRequested',
  // x00191044: 'InterscanDelay',
  // x00191047: 'ViewCompressionFactor',
  // x0019104a: 'TotalNoOfRefChannels',
  // x0019104b: 'DataSizeForScanData',
  // x00191052: 'ReconPostProcflag',
  // x00191057: 'CTWaterNumber',
  // x00191058: 'CTBoneNumber',
  // x0019105a: 'AcquisitionDuration',
  // x0019105e: 'NumberOfChannels',
  // x0019105f: 'IncrementBetweenChannels',
  // x00191060: 'StartingView',
  // x00191061: 'NumberOfViews',
  // x00191062: 'IncrementBetweenViews',
  // x0019106a: 'DependantOnNoViewsProcessed',
  // x0019106b: 'FieldOfViewInDetectorCells',
  // x00191070: 'ValueOfBackProjectionButton',
  // x00191071: 'SetIfFatqEstimatesWereUsed',
  // x00191072: 'ZChanAvgOverViews',
  // x00191073: 'AvgOfLeftRefChansOverViews',
  // x00191074: 'MaxLeftChanOverViews',
  // x00191075: 'AvgOfRightRefChansOverViews',
  // x00191076: 'MaxRightChanOverViews',
  // x0019107d: 'SecondEcho',
  // x0019107e: 'NumberOfEchoes',
  // x0019107f: 'TableDelta',
  // x00191081: 'Contiguous',
  // x00191084: 'PeakSAR',
  // x00191085: 'MonitorSAR',
  // x00191087: 'CardiacRepetitionTime',
  // x00191088: 'ImagesPerCardiacCycle',
  // x0019108a: 'ActualReceiveGainAnalog',
  // x0019108b: 'ActualReceiveGainDigital',
  // x0019108d: 'DelayAfterTrigger',
  // x0019108f: 'Swappf',
  // x00191090: 'PauseInterval',
  // x00191091: 'PulseTime',
  // x00191092: 'SliceOffsetOnFreqAxis',
  // x00191093: 'CenterFrequency',
  // x00191094: 'TransmitGain',
  // x00191095: 'AnalogReceiverGain',
  // x00191096: 'DigitalReceiverGain',
  // x00191097: 'BitmapDefiningCVs',
  // x00191098: 'CenterFreqMethod',
  // x0019109b: 'PulseSeqMode',
  // x0019109c: 'PulseSeqName',
  // x0019109d: 'PulseSeqDate',
  // x0019109e: 'InternalPulseSeqName',
  // x0019109f: 'TransmittingCoil',
  // x001910a0: 'SurfaceCoilType',
  // x001910a1: 'ExtremityCoilFlag',
  // x001910a2: 'RawDataRunNumber',
  // x001910a3: 'CalibratedFieldStrength',
  // x001910a4: 'SATFatWaterBone',
  // x001910a5: 'ReceiveBandwidth',
  // x001910a7: 'UserData01',
  // x001910a8: 'UserData02',
  // x001910a9: 'UserData03',
  // x001910aa: 'UserData04',
  // x001910ab: 'UserData05',
  // x001910ac: 'UserData06',
  // x001910ad: 'UserData07',
  // x001910ae: 'UserData08',
  // x001910af: 'UserData09',
  // x001910b0: 'UserData10',
  // x001910b1: 'UserData11',
  // x001910b2: 'UserData12',
  // x001910b3: 'UserData13',
  // x001910b4: 'UserData14',
  // x001910b5: 'UserData15',
  // x001910b6: 'UserData16',
  // x001910b7: 'UserData17',
  // x001910b8: 'UserData18',
  // x001910b9: 'UserData19',
  // x001910ba: 'UserData20',
  // x001910bb: 'UserData21',
  // x001910bc: 'UserData22',
  // x001910bd: 'UserData23',
  // x001910be: 'ProjectionAngle',
  // x001910c0: 'SaturationPlanes',
  // x001910c1: 'SurfaceCoilIntensity',
  // x001910c2: 'SATLocationR',
  // x001910c3: 'SATLocationL',
  // x001910c4: 'SATLocationA',
  // x001910c5: 'SATLocationP',
  // x001910c6: 'SATLocationH',
  // x001910c7: 'SATLocationF',
  // x001910c8: 'SATThicknessR-L',
  // x001910c9: 'SATThicknessA-P',
  // x001910ca: 'SATThicknessH-F',
  // x001910cb: 'PrescribedFlowAxis',
  // x001910cc: 'VelocityEncoding',
  // x001910cd: 'ThicknessDisclaimer',
  // x001910ce: 'PrescanType',
  // x001910cf: 'PrescanStatus',
  // x001910d0: 'RawDataType',
  // x001910d2: 'ProjectionAlgorithm',
  // x001910d3: 'ProjectionAlgorithm',
  // x001910d5: 'FractionalEcho',
  // x001910d6: 'PrepPulse',
  // x001910d7: 'CardiacPhases',
  // x001910d8: 'VariableEchoflag',
  // x001910d9: 'ConcatenatedSAT',
  // x001910da: 'ReferenceChannelUsed',
  // x001910db: 'BackProjectorCoefficient',
  // x001910dc: 'PrimarySpeedCorrectionUsed',
  // x001910dd: 'OverrangeCorrectionUsed',
  // x001910de: 'DynamicZAlphaValue',
  // x001910df: 'UserData',
  // x001910e0: 'UserData',
  // x001910e2: 'VelocityEncodeScale',
  // x001910f2: 'FastPhases',
  // x001910f9: 'TransmissionGain',
  x00200000: 'RelationshipGroupLength',
  x0020000d: 'StudyInstanceUID',
  x0020000e: 'SeriesInstanceUID',
  x00200010: 'StudyID',
  x00200011: 'SeriesNumber',
  x00200012: 'AcquisitionNumber',
  x00200013: 'InstanceNumber',
  x00200014: 'IsotopeNumber',
  x00200015: 'PhaseNumber',
  x00200016: 'IntervalNumber',
  x00200017: 'TimeSlotNumber',
  x00200018: 'AngleNumber',
  x00200019: 'ItemNumber',
  x00200020: 'PatientOrientation',
  x00200022: 'OverlayNumber',
  x00200024: 'CurveNumber',
  x00200026: 'LookupTableNumber',
  x00200030: 'ImagePosition',
  x00200032: 'ImagePositionPatient',
  x00200035: 'ImageOrientation',
  x00200037: 'ImageOrientationPatient',
  x00200050: 'Location',
  x00200052: 'FrameOfReferenceUID',
  x00200060: 'Laterality',
  x00200062: 'ImageLaterality',
  x00200070: 'ImageGeometryType',
  x00200080: 'MaskingImage',
  x00200100: 'TemporalPositionIdentifier',
  x00200105: 'NumberOfTemporalPositions',
  x00200110: 'TemporalResolution',
  x00200200: 'SynchronizationFrameOfReferenceUID',
  x00201000: 'SeriesInStudy',
  x00201001: 'AcquisitionsInSeries',
  x00201002: 'ImagesInAcquisition',
  x00201003: 'ImagesInSeries',
  x00201004: 'AcquisitionsInStudy',
  x00201005: 'ImagesInStudy',
  x00201020: 'Reference',
  x00201040: 'PositionReferenceIndicator',
  x00201041: 'SliceLocation',
  x00201070: 'OtherStudyNumbers',
  x00201200: 'NumberOfPatientRelatedStudies',
  x00201202: 'NumberOfPatientRelatedSeries',
  x00201204: 'NumberOfPatientRelatedInstances',
  x00201206: 'NumberOfStudyRelatedSeries',
  x00201208: 'NumberOfStudyRelatedInstances',
  x00201209: 'NumberOfSeriesRelatedInstances',
  x002031xx: 'SourceImageIDs',
  x00203401: 'ModifyingDeviceID',
  x00203402: 'ModifiedImageID',
  x00203403: 'ModifiedImageDate',
  x00203404: 'ModifyingDeviceManufacturer',
  x00203405: 'ModifiedImageTime',
  x00203406: 'ModifiedImageDescription',
  x00204000: 'ImageComments',
  x00205000: 'OriginalImageIdentification',
  x00205002: 'OriginalImageIdentNomenclature',
  x00209056: 'StackID',
  x00209057: 'InStackPositionNumber',
  x00209071: 'FrameAnatomySequence',
  x00209072: 'FrameLaterality',
  x00209111: 'FrameContentSequence',
  x00209113: 'PlanePositionSequence',
  x00209116: 'PlaneOrientationSequence',
  x00209128: 'TemporalPositionIndex',
  x00209153: 'TriggerDelayTime',
  x00209156: 'FrameAcquisitionNumber',
  x00209157: 'DimensionIndexValues',
  x00209158: 'FrameComments',
  x00209161: 'ConcatenationUID',
  x00209162: 'InConcatenationNumber',
  x00209163: 'InConcatenationTotalNumber',
  x00209164: 'DimensionOrganizationUID',
  x00209165: 'DimensionIndexPointer',
  x00209167: 'FunctionalGroupPointer',
  x00209213: 'DimensionIndexPrivateCreator',
  x00209221: 'DimensionOrganizationSequence',
  x00209222: 'DimensionIndexSequence',
  x00209228: 'ConcatenationFrameOffsetNumber',
  x00209238: 'FunctionalGroupPrivateCreator',
  x00209241: 'NominalPercentageOfCardiacPhase',
  x00209245: 'NominalPercentOfRespiratoryPhase',
  x00209246: 'StartingRespiratoryAmplitude',
  x00209247: 'StartingRespiratoryPhase',
  x00209248: 'EndingRespiratoryAmplitude',
  x00209249: 'EndingRespiratoryPhase',
  x00209250: 'RespiratoryTriggerType',
  x00209251: 'RRIntervalTimeNominal',
  x00209252: 'ActualCardiacTriggerDelayTime',
  x00209253: 'RespiratorySynchronizationSequence',
  x00209254: 'RespiratoryIntervalTime',
  x00209255: 'NominalRespiratoryTriggerDelayTime',
  x00209256: 'RespiratoryTriggerDelayThreshold',
  x00209257: 'ActualRespiratoryTriggerDelayTime',
  x00209301: 'ImagePositionVolume',
  x00209302: 'ImageOrientationVolume',
  x00209308: 'ApexPosition',
  x00209421: 'DimensionDescriptionLabel',
  x00209450: 'PatientOrientationInFrameSequence',
  x00209453: 'FrameLabel',
  x00209518: 'AcquisitionIndex',
  x00209529: 'ContributingSOPInstancesRefSeq',
  x00209536: 'ReconstructionIndex',
  // x00211003: 'SeriesFromWhichPrescribed',
  // x00211005: 'GenesisVersionNow',
  // x00211007: 'SeriesRecordChecksum',
  // x00211018: 'GenesisVersionNow',
  // x00211019: 'AcqreconRecordChecksum',
  // x00211020: 'TableStartLocation',
  // x00211035: 'SeriesFromWhichPrescribed',
  // x00211036: 'ImageFromWhichPrescribed',
  // x00211037: 'ScreenFormat',
  // x0021104a: 'AnatomicalReferenceForScout',
  // x0021104f: 'LocationsInAcquisition',
  // x00211050: 'GraphicallyPrescribed',
  // x00211051: 'RotationFromSourceXRot',
  // x00211052: 'RotationFromSourceYRot',
  // x00211053: 'RotationFromSourceZRot',
  // x00211054: 'ImagePosition',
  // x00211055: 'ImageOrientation',
  // x00211056: 'IntegerSlop',
  // x00211057: 'IntegerSlop',
  // x00211058: 'IntegerSlop',
  // x00211059: 'IntegerSlop',
  // x0021105a: 'IntegerSlop',
  // x0021105b: 'FloatSlop',
  // x0021105c: 'FloatSlop',
  // x0021105d: 'FloatSlop',
  // x0021105e: 'FloatSlop',
  // x0021105f: 'FloatSlop',
  // x00211081: 'AutoWindowLevelAlpha',
  // x00211082: 'AutoWindowLevelBeta',
  // x00211083: 'AutoWindowLevelWindow',
  // x00211084: 'ToWindowLevelLevel',
  // x00211090: 'TubeFocalSpotPosition',
  // x00211091: 'BiopsyPosition',
  // x00211092: 'BiopsyTLocation',
  // x00211093: 'BiopsyRefLocation',
  x00220001: 'LightPathFilterPassThroughWavelen',
  x00220002: 'LightPathFilterPassBand',
  x00220003: 'ImagePathFilterPassThroughWavelen',
  x00220004: 'ImagePathFilterPassBand',
  x00220005: 'PatientEyeMovementCommanded',
  x00220006: 'PatientEyeMovementCommandCodeSeq',
  x00220007: 'SphericalLensPower',
  x00220008: 'CylinderLensPower',
  x00220009: 'CylinderAxis',
  x0022000a: 'EmmetropicMagnification',
  x0022000b: 'IntraOcularPressure',
  x0022000c: 'HorizontalFieldOfView',
  x0022000d: 'PupilDilated',
  x0022000e: 'DegreeOfDilation',
  x00220010: 'StereoBaselineAngle',
  x00220011: 'StereoBaselineDisplacement',
  x00220012: 'StereoHorizontalPixelOffset',
  x00220013: 'StereoVerticalPixelOffset',
  x00220014: 'StereoRotation',
  x00220015: 'AcquisitionDeviceTypeCodeSequence',
  x00220016: 'IlluminationTypeCodeSequence',
  x00220017: 'LightPathFilterTypeStackCodeSeq',
  x00220018: 'ImagePathFilterTypeStackCodeSeq',
  x00220019: 'LensesCodeSequence',
  x0022001a: 'ChannelDescriptionCodeSequence',
  x0022001b: 'RefractiveStateSequence',
  x0022001c: 'MydriaticAgentCodeSequence',
  x0022001d: 'RelativeImagePositionCodeSequence',
  x00220020: 'StereoPairsSequence',
  x00220021: 'LeftImageSequence',
  x00220022: 'RightImageSequence',
  x00220030: 'AxialLengthOfTheEye',
  x00220031: 'OphthalmicFrameLocationSequence',
  x00220032: 'ReferenceCoordinates',
  x00220035: 'DepthSpatialResolution',
  x00220036: 'MaximumDepthDistortion',
  x00220037: 'AlongScanSpatialResolution',
  x00220038: 'MaximumAlongScanDistortion',
  x00220039: 'OphthalmicImageOrientation',
  x00220041: 'DepthOfTransverseImage',
  x00220042: 'MydriaticAgentConcUnitsSeq',
  x00220048: 'AcrossScanSpatialResolution',
  x00220049: 'MaximumAcrossScanDistortion',
  x0022004e: 'MydriaticAgentConcentration',
  x00220055: 'IlluminationWaveLength',
  x00220056: 'IlluminationPower',
  x00220057: 'IlluminationBandwidth',
  x00220058: 'MydriaticAgentSequence',
  // x00231001: 'NumberOfSeriesInStudy',
  // x00231002: 'NumberOfUnarchivedSeries',
  // x00231010: 'ReferenceImageField',
  // x00231050: 'SummaryImage',
  // x00231070: 'StartTimeSecsInFirstAxial',
  // x00231074: 'NoofUpdatesToHeader',
  // x0023107d: 'IndicatesIfTheStudyHasCompleteInfo',
  // x00251006: 'LastPulseSequenceUsed',
  // x00251007: 'ImagesInSeries',
  // x00251010: 'LandmarkCounter',
  // x00251011: 'NumberOfAcquisitions',
  // x00251014: 'IndicatesNoofUpdatesToHeader',
  // x00251017: 'SeriesCompleteFlag',
  // x00251018: 'NumberOfImagesArchived',
  // x00251019: 'LastImageNumberUsed',
  // x0025101a: 'PrimaryReceiverSuiteAndHost',
  // x00271006: 'ImageArchiveFlag',
  // x00271010: 'ScoutType',
  // x0027101c: 'VmaMamp',
  // x0027101d: 'VmaPhase',
  // x0027101e: 'VmaMod',
  // x0027101f: 'VmaClip',
  // x00271020: 'SmartScanOnOffFlag',
  // x00271030: 'ForeignImageRevision',
  // x00271031: 'ImagingMode',
  // x00271032: 'PulseSequence',
  // x00271033: 'ImagingOptions',
  // x00271035: 'PlaneType',
  // x00271036: 'ObliquePlane',
  // x00271040: 'RASLetterOfImageLocation',
  // x00271041: 'ImageLocation',
  // x00271042: 'CenterRCoordOfPlaneImage',
  // x00271043: 'CenterACoordOfPlaneImage',
  // x00271044: 'CenterSCoordOfPlaneImage',
  // x00271045: 'NormalRCoord',
  // x00271046: 'NormalACoord',
  // x00271047: 'NormalSCoord',
  // x00271048: 'RCoordOfTopRightCorner',
  // x00271049: 'ACoordOfTopRightCorner',
  // x0027104a: 'SCoordOfTopRightCorner',
  // x0027104b: 'RCoordOfBottomRightCorner',
  // x0027104c: 'ACoordOfBottomRightCorner',
  // x0027104d: 'SCoordOfBottomRightCorner',
  // x00271050: 'TableStartLocation',
  // x00271051: 'TableEndLocation',
  // x00271052: 'RASLetterForSideOfImage',
  // x00271053: 'RASLetterForAnteriorPosterior',
  // x00271054: 'RASLetterForScoutStartLoc',
  // x00271055: 'RASLetterForScoutEndLoc',
  // x00271060: 'ImageDimensionX',
  // x00271061: 'ImageDimensionY',
  // x00271062: 'NumberOfExcitations',
  x00280000: 'ImagePresentationGroupLength',
  x00280002: 'SamplesPerPixel',
  x00280003: 'SamplesPerPixelUsed',
  x00280004: 'PhotometricInterpretation',
  x00280005: 'ImageDimensions',
  x00280006: 'PlanarConfiguration',
  x00280008: 'NumberOfFrames',
  x00280009: 'FrameIncrementPointer',
  x0028000a: 'FrameDimensionPointer',
  x00280010: 'Rows',
  x00280011: 'Columns',
  x00280012: 'Planes',
  x00280014: 'UltrasoundColorDataPresent',
  x00280030: 'PixelSpacing',
  x00280031: 'ZoomFactor',
  x00280032: 'ZoomCenter',
  x00280034: 'PixelAspectRatio',
  x00280040: 'ImageFormat',
  x00280050: 'ManipulatedImage',
  x00280051: 'CorrectedImage',
  x0028005f: 'CompressionRecognitionCode',
  x00280060: 'CompressionCode',
  x00280061: 'CompressionOriginator',
  x00280062: 'CompressionLabel',
  x00280063: 'CompressionDescription',
  x00280065: 'CompressionSequence',
  x00280066: 'CompressionStepPointers',
  x00280068: 'RepeatInterval',
  x00280069: 'BitsGrouped',
  x00280070: 'PerimeterTable',
  x00280071: 'PerimeterValue',
  x00280080: 'PredictorRows',
  x00280081: 'PredictorColumns',
  x00280082: 'PredictorConstants',
  x00280090: 'BlockedPixels',
  x00280091: 'BlockRows',
  x00280092: 'BlockColumns',
  x00280093: 'RowOverlap',
  x00280094: 'ColumnOverlap',
  x00280100: 'BitsAllocated',
  x00280101: 'BitsStored',
  x00280102: 'HighBit',
  x00280103: 'PixelRepresentation',
  x00280104: 'SmallestValidPixelValue',
  x00280105: 'LargestValidPixelValue',
  x00280106: 'SmallestImagePixelValue',
  x00280107: 'LargestImagePixelValue',
  x00280108: 'SmallestPixelValueInSeries',
  x00280109: 'LargestPixelValueInSeries',
  x00280110: 'SmallestImagePixelValueInPlane',
  x00280111: 'LargestImagePixelValueInPlane',
  x00280120: 'PixelPaddingValue',
  x00280121: 'PixelPaddingRangeLimit',
  x00280200: 'ImageLocation',
  x00280300: 'QualityControlImage',
  x00280301: 'BurnedInAnnotation',
  x00280400: 'TransformLabel',
  x00280401: 'TransformVersionNumber',
  x00280402: 'NumberOfTransformSteps',
  x00280403: 'SequenceOfCompressedData',
  x00280404: 'DetailsOfCoefficients',
  x002804x2: 'CoefficientCoding',
  x002804x3: 'CoefficientCodingPointers',
  x00280700: 'DCTLabel',
  x00280701: 'DataBlockDescription',
  x00280702: 'DataBlock',
  x00280710: 'NormalizationFactorFormat',
  x00280720: 'ZonalMapNumberFormat',
  x00280721: 'ZonalMapLocation',
  x00280722: 'ZonalMapFormat',
  x00280730: 'AdaptiveMapFormat',
  x00280740: 'CodeNumberFormat',
  x002808x0: 'CodeLabel',
  x002808x2: 'NumberOfTables',
  x002808x3: 'CodeTableLocation',
  x002808x4: 'BitsForCodeWord',
  x002808x8: 'ImageDataLocation',
  x00280a02: 'PixelSpacingCalibrationType',
  x00280a04: 'PixelSpacingCalibrationDescription',
  x00281040: 'PixelIntensityRelationship',
  x00281041: 'PixelIntensityRelationshipSign',
  x00281050: 'WindowCenter',
  x00281051: 'WindowWidth',
  x00281052: 'RescaleIntercept',
  x00281053: 'RescaleSlope',
  x00281054: 'RescaleType',
  x00281055: 'WindowCenterAndWidthExplanation',
  x00281056: 'VOI_LUTFunction',
  x00281080: 'GrayScale',
  x00281090: 'RecommendedViewingMode',
  x00281100: 'GrayLookupTableDescriptor',
  x00281101: 'RedPaletteColorTableDescriptor',
  x00281102: 'GreenPaletteColorTableDescriptor',
  x00281103: 'BluePaletteColorTableDescriptor',
  x00281111: 'LargeRedPaletteColorTableDescr',
  x00281112: 'LargeGreenPaletteColorTableDescr',
  x00281113: 'LargeBluePaletteColorTableDescr',
  x00281199: 'PaletteColorTableUID',
  x00281200: 'GrayLookupTableData',
  x00281201: 'RedPaletteColorTableData',
  x00281202: 'GreenPaletteColorTableData',
  x00281203: 'BluePaletteColorTableData',
  x00281211: 'LargeRedPaletteColorTableData',
  x00281212: 'LargeGreenPaletteColorTableData',
  x00281213: 'LargeBluePaletteColorTableData',
  x00281214: 'LargePaletteColorLookupTableUID',
  x00281221: 'SegmentedRedColorTableData',
  x00281222: 'SegmentedGreenColorTableData',
  x00281223: 'SegmentedBlueColorTableData',
  x00281300: 'BreastImplantPresent',
  x00281350: 'PartialView',
  x00281351: 'PartialViewDescription',
  x00281352: 'PartialViewCodeSequence',
  x0028135a: 'SpatialLocationsPreserved',
  x00281402: 'DataPathAssignment',
  x00281404: 'BlendingLUT1Sequence',
  x00281406: 'BlendingWeightConstant',
  x00281408: 'BlendingLookupTableData',
  x0028140c: 'BlendingLUT2Sequence',
  x0028140e: 'DataPathID',
  x0028140f: 'RGBLUTTransferFunction',
  x00281410: 'AlphaLUTTransferFunction',
  x00282000: 'ICCProfile',
  x00282110: 'LossyImageCompression',
  x00282112: 'LossyImageCompressionRatio',
  x00282114: 'LossyImageCompressionMethod',
  x00283000: 'ModalityLUTSequence',
  x00283002: 'LUTDescriptor',
  x00283003: 'LUTExplanation',
  x00283004: 'ModalityLUTType',
  x00283006: 'LUTData',
  x00283010: 'VOILUTSequence',
  x00283110: 'SoftcopyVOILUTSequence',
  x00284000: 'ImagePresentationComments',
  x00285000: 'BiPlaneAcquisitionSequence',
  x00286010: 'RepresentativeFrameNumber',
  x00286020: 'FrameNumbersOfInterest',
  x00286022: 'FrameOfInterestDescription',
  x00286023: 'FrameOfInterestType',
  x00286030: 'MaskPointers',
  x00286040: 'RWavePointer',
  x00286100: 'MaskSubtractionSequence',
  x00286101: 'MaskOperation',
  x00286102: 'ApplicableFrameRange',
  x00286110: 'MaskFrameNumbers',
  x00286112: 'ContrastFrameAveraging',
  x00286114: 'MaskSubPixelShift',
  x00286120: 'TIDOffset',
  x00286190: 'MaskOperationExplanation',
  x00287fe0: 'PixelDataProviderURL',
  x00289001: 'DataPointRows',
  x00289002: 'DataPointColumns',
  x00289003: 'SignalDomainColumns',
  x00289099: 'LargestMonochromePixelValue',
  x00289108: 'DataRepresentation',
  x00289110: 'PixelMeasuresSequence',
  x00289132: 'FrameVOILUTSequence',
  x00289145: 'PixelValueTransformationSequence',
  x00289235: 'SignalDomainRows',
  x00289411: 'DisplayFilterPercentage',
  x00289415: 'FramePixelShiftSequence',
  x00289416: 'SubtractionItemID',
  x00289422: 'PixelIntensityRelationshipLUTSeq',
  x00289443: 'FramePixelDataPropertiesSequence',
  x00289444: 'GeometricalProperties',
  x00289445: 'GeometricMaximumDistortion',
  x00289446: 'ImageProcessingApplied',
  x00289454: 'MaskSelectionMode',
  x00289474: 'LUTFunction',
  x00289478: 'MaskVisibilityPercentage',
  x00289501: 'PixelShiftSequence',
  x00289502: 'RegionPixelShiftSequence',
  x00289503: 'VerticesOfTheRegion',
  x00289506: 'PixelShiftFrameRange',
  x00289507: 'LUTFrameRange',
  x00289520: 'ImageToEquipmentMappingMatrix',
  x00289537: 'EquipmentCoordinateSystemID',
  // x00291004: 'LowerRangeOfPixels1a',
  // x00291005: 'LowerRangeOfPixels1b',
  // x00291006: 'LowerRangeOfPixels1c',
  // x00291007: 'LowerRangeOfPixels1d',
  // x00291008: 'LowerRangeOfPixels1e',
  // x00291009: 'LowerRangeOfPixels1f',
  // x0029100a: 'LowerRangeOfPixels1g',
  // x00291015: 'LowerRangeOfPixels1h',
  // x00291016: 'LowerRangeOfPixels1i',
  // x00291017: 'LowerRangeOfPixels2',
  // x00291018: 'UpperRangeOfPixels2',
  // x0029101a: 'LenOfTotHdrInBytes',
  // x00291026: 'VersionOfTheHdrStruct',
  // x00291034: 'AdvantageCompOverflow',
  // x00291035: 'AdvantageCompUnderflow',
  x00320000: 'StudyGroupLength',
  x0032000a: 'StudyStatusID',
  x0032000c: 'StudyPriorityID',
  x00320012: 'StudyIDIssuer',
  x00320032: 'StudyVerifiedDate',
  x00320033: 'StudyVerifiedTime',
  x00320034: 'StudyReadDate',
  x00320035: 'StudyReadTime',
  x00321000: 'ScheduledStudyStartDate',
  x00321001: 'ScheduledStudyStartTime',
  x00321010: 'ScheduledStudyStopDate',
  x00321011: 'ScheduledStudyStopTime',
  x00321020: 'ScheduledStudyLocation',
  x00321021: 'ScheduledStudyLocationAETitle',
  x00321030: 'ReasonForStudy',
  x00321031: 'RequestingPhysicianIDSequence',
  x00321032: 'RequestingPhysician',
  x00321033: 'RequestingService',
  x00321040: 'StudyArrivalDate',
  x00321041: 'StudyArrivalTime',
  x00321050: 'StudyCompletionDate',
  x00321051: 'StudyCompletionTime',
  x00321055: 'StudyComponentStatusID',
  x00321060: 'RequestedProcedureDescription',
  x00321064: 'RequestedProcedureCodeSequence',
  x00321070: 'RequestedContrastAgent',
  x00324000: 'StudyComments',
  x00380004: 'ReferencedPatientAliasSequence',
  x00380008: 'VisitStatusID',
  x00380010: 'AdmissionID',
  x00380011: 'IssuerOfAdmissionID',
  x00380016: 'RouteOfAdmissions',
  x0038001a: 'ScheduledAdmissionDate',
  x0038001b: 'ScheduledAdmissionTime',
  x0038001c: 'ScheduledDischargeDate',
  x0038001d: 'ScheduledDischargeTime',
  x0038001e: 'ScheduledPatientInstitResidence',
  x00380020: 'AdmittingDate',
  x00380021: 'AdmittingTime',
  x00380030: 'DischargeDate',
  x00380032: 'DischargeTime',
  x00380040: 'DischargeDiagnosisDescription',
  x00380044: 'DischargeDiagnosisCodeSequence',
  x00380050: 'SpecialNeeds',
  x00380060: 'ServiceEpisodeID',
  x00380061: 'IssuerOfServiceEpisodeID',
  x00380062: 'ServiceEpisodeDescription',
  x00380100: 'PertinentDocumentsSequence',
  x00380300: 'CurrentPatientLocation',
  x00380400: 'PatientInstitutionResidence',
  x00380500: 'PatientState',
  x00380502: 'PatientClinicalTrialParticipSeq',
  x00384000: 'VisitComments',
  x003a0004: 'WaveformOriginality',
  x003a0005: 'NumberOfWaveformChannels',
  x003a0010: 'NumberOfWaveformSamples',
  x003a001a: 'SamplingFrequency',
  x003a0020: 'MultiplexGroupLabel',
  x003a0200: 'ChannelDefinitionSequence',
  x003a0202: 'WaveformChannelNumber',
  x003a0203: 'ChannelLabel',
  x003a0205: 'ChannelStatus',
  x003a0208: 'ChannelSourceSequence',
  x003a0209: 'ChannelSourceModifiersSequence',
  x003a020a: 'SourceWaveformSequence',
  x003a020c: 'ChannelDerivationDescription',
  x003a0210: 'ChannelSensitivity',
  x003a0211: 'ChannelSensitivityUnitsSequence',
  x003a0212: 'ChannelSensitivityCorrectionFactor',
  x003a0213: 'ChannelBaseline',
  x003a0214: 'ChannelTimeSkew',
  x003a0215: 'ChannelSampleSkew',
  x003a0218: 'ChannelOffset',
  x003a021a: 'WaveformBitsStored',
  x003a0220: 'FilterLowFrequency',
  x003a0221: 'FilterHighFrequency',
  x003a0222: 'NotchFilterFrequency',
  x003a0223: 'NotchFilterBandwidth',
  x003a0230: 'WaveformDataDisplayScale',
  x003a0231: 'WaveformDisplayBkgCIELabValue',
  x003a0240: 'WaveformPresentationGroupSequence',
  x003a0241: 'PresentationGroupNumber',
  x003a0242: 'ChannelDisplaySequence',
  x003a0244: 'ChannelRecommendDisplayCIELabValue',
  x003a0245: 'ChannelPosition',
  x003a0246: 'DisplayShadingFlag',
  x003a0247: 'FractionalChannelDisplayScale',
  x003a0248: 'AbsoluteChannelDisplayScale',
  x003a0300: 'MultiplexAudioChannelsDescrCodeSeq',
  x003a0301: 'ChannelIdentificationCode',
  x003a0302: 'ChannelMode',
  x00400001: 'ScheduledStationAETitle',
  x00400002: 'ScheduledProcedureStepStartDate',
  x00400003: 'ScheduledProcedureStepStartTime',
  x00400004: 'ScheduledProcedureStepEndDate',
  x00400005: 'ScheduledProcedureStepEndTime',
  x00400006: 'ScheduledPerformingPhysiciansName',
  x00400007: 'ScheduledProcedureStepDescription',
  x00400008: 'ScheduledProtocolCodeSequence',
  x00400009: 'ScheduledProcedureStepID',
  x0040000a: 'StageCodeSequence',
  x0040000b: 'ScheduledPerformingPhysicianIDSeq',
  x00400010: 'ScheduledStationName',
  x00400011: 'ScheduledProcedureStepLocation',
  x00400012: 'PreMedication',
  x00400020: 'ScheduledProcedureStepStatus',
  x00400031: 'LocalNamespaceEntityID',
  x00400032: 'UniversalEntityID',
  x00400033: 'UniversalEntityIDType',
  x00400035: 'IdentifierTypeCode',
  x00400036: 'AssigningFacilitySequence',
  x00400100: 'ScheduledProcedureStepSequence',
  x00400220: 'ReferencedNonImageCompositeSOPSeq',
  x00400241: 'PerformedStationAETitle',
  x00400242: 'PerformedStationName',
  x00400243: 'PerformedLocation',
  x00400244: 'PerformedProcedureStepStartDate',
  x00400245: 'PerformedProcedureStepStartTime',
  x00400250: 'PerformedProcedureStepEndDate',
  x00400251: 'PerformedProcedureStepEndTime',
  x00400252: 'PerformedProcedureStepStatus',
  x00400253: 'PerformedProcedureStepID',
  x00400254: 'PerformedProcedureStepDescription',
  x00400255: 'PerformedProcedureTypeDescription',
  x00400260: 'PerformedProtocolCodeSequence',
  x00400261: 'PerformedProtocolType',
  x00400270: 'ScheduledStepAttributesSequence',
  x00400275: 'RequestAttributesSequence',
  x00400280: 'CommentsOnPerformedProcedureStep',
  x00400281: 'ProcStepDiscontinueReasonCodeSeq',
  x00400293: 'QuantitySequence',
  x00400294: 'Quantity',
  x00400295: 'MeasuringUnitsSequence',
  x00400296: 'BillingItemSequence',
  x00400300: 'TotalTimeOfFluoroscopy',
  x00400301: 'TotalNumberOfExposures',
  x00400302: 'EntranceDose',
  x00400303: 'ExposedArea',
  x00400306: 'DistanceSourceToEntrance',
  x00400307: 'DistanceSourceToSupport',
  x0040030e: 'ExposureDoseSequence',
  x00400310: 'CommentsOnRadiationDose',
  x00400312: 'XRayOutput',
  x00400314: 'HalfValueLayer',
  x00400316: 'OrganDose',
  x00400318: 'OrganExposed',
  x00400320: 'BillingProcedureStepSequence',
  x00400321: 'FilmConsumptionSequence',
  x00400324: 'BillingSuppliesAndDevicesSequence',
  x00400330: 'ReferencedProcedureStepSequence',
  x00400340: 'PerformedSeriesSequence',
  x00400400: 'CommentsOnScheduledProcedureStep',
  x00400440: 'ProtocolContextSequence',
  x00400441: 'ContentItemModifierSequence',
  x0040050a: 'SpecimenAccessionNumber',
  x00400512: 'ContainerIdentifier',
  x0040051a: 'ContainerDescription',
  x00400550: 'SpecimenSequence',
  x00400551: 'SpecimenIdentifier',
  x00400552: 'SpecimenDescriptionSequenceTrial',
  x00400553: 'SpecimenDescriptionTrial',
  x00400554: 'SpecimenUID',
  x00400555: 'AcquisitionContextSequence',
  x00400556: 'AcquisitionContextDescription',
  x0040059a: 'SpecimenTypeCodeSequence',
  x00400600: 'SpecimenShortDescription',
  x004006fa: 'SlideIdentifier',
  x0040071a: 'ImageCenterPointCoordinatesSeq',
  x0040072a: 'XOffsetInSlideCoordinateSystem',
  x0040073a: 'YOffsetInSlideCoordinateSystem',
  x0040074a: 'ZOffsetInSlideCoordinateSystem',
  x004008d8: 'PixelSpacingSequence',
  x004008da: 'CoordinateSystemAxisCodeSequence',
  x004008ea: 'MeasurementUnitsCodeSequence',
  x004009f8: 'VitalStainCodeSequenceTrial',
  x00401001: 'RequestedProcedureID',
  x00401002: 'ReasonForRequestedProcedure',
  x00401003: 'RequestedProcedurePriority',
  x00401004: 'PatientTransportArrangements',
  x00401005: 'RequestedProcedureLocation',
  x00401006: 'PlacerOrderNumber-Procedure',
  x00401007: 'FillerOrderNumber-Procedure',
  x00401008: 'ConfidentialityCode',
  x00401009: 'ReportingPriority',
  x0040100a: 'ReasonForRequestedProcedureCodeSeq',
  x00401010: 'NamesOfIntendedRecipientsOfResults',
  x00401011: 'IntendedRecipientsOfResultsIDSeq',
  x00401101: 'PersonIdentificationCodeSequence',
  x00401102: 'PersonAddress',
  x00401103: 'PersonTelephoneNumbers',
  x00401400: 'RequestedProcedureComments',
  x00402001: 'ReasonForImagingServiceRequest',
  x00402004: 'IssueDateOfImagingServiceRequest',
  x00402005: 'IssueTimeOfImagingServiceRequest',
  x00402006: 'PlacerOrderNumberImagingServiceRequestRetired',
  x00402007: 'FillerOrderNumberImagingServiceRequestRetired',
  x00402008: 'OrderEnteredBy',
  x00402009: 'OrderEntererLocation',
  x00402010: 'OrderCallbackPhoneNumber',
  x00402016: 'PlacerOrderNum-ImagingServiceReq',
  x00402017: 'FillerOrderNum-ImagingServiceReq',
  x00402400: 'ImagingServiceRequestComments',
  x00403001: 'ConfidentialityOnPatientDataDescr',
  x00404001: 'GenPurposeScheduledProcStepStatus',
  x00404002: 'GenPurposePerformedProcStepStatus',
  x00404003: 'GenPurposeSchedProcStepPriority',
  x00404004: 'SchedProcessingApplicationsCodeSeq',
  x00404005: 'SchedProcedureStepStartDateAndTime',
  x00404006: 'MultipleCopiesFlag',
  x00404007: 'PerformedProcessingAppsCodeSeq',
  x00404009: 'HumanPerformerCodeSequence',
  x00404010: 'SchedProcStepModificationDateTime',
  x00404011: 'ExpectedCompletionDateAndTime',
  x00404015: 'ResultingGenPurposePerfProcStepSeq',
  x00404016: 'RefGenPurposeSchedProcStepSeq',
  x00404018: 'ScheduledWorkitemCodeSequence',
  x00404019: 'PerformedWorkitemCodeSequence',
  x00404020: 'InputAvailabilityFlag',
  x00404021: 'InputInformationSequence',
  x00404022: 'RelevantInformationSequence',
  x00404023: 'RefGenPurSchedProcStepTransUID',
  x00404025: 'ScheduledStationNameCodeSequence',
  x00404026: 'ScheduledStationClassCodeSequence',
  x00404027: 'SchedStationGeographicLocCodeSeq',
  x00404028: 'PerformedStationNameCodeSequence',
  x00404029: 'PerformedStationClassCodeSequence',
  x00404030: 'PerformedStationGeogLocCodeSeq',
  x00404031: 'RequestedSubsequentWorkItemCodeSeq',
  x00404032: 'NonDICOMOutputCodeSequence',
  x00404033: 'OutputInformationSequence',
  x00404034: 'ScheduledHumanPerformersSequence',
  x00404035: 'ActualHumanPerformersSequence',
  x00404036: 'HumanPerformersOrganization',
  x00404037: 'HumanPerformerName',
  x00404040: 'RawDataHandling',
  x00408302: 'EntranceDoseInMilliGy',
  x00409094: 'RefImageRealWorldValueMappingSeq',
  x00409096: 'RealWorldValueMappingSequence',
  x00409098: 'PixelValueMappingCodeSequence',
  x00409210: 'LUTLabel',
  x00409211: 'RealWorldValueLastValueMapped',
  x00409212: 'RealWorldValueLUTData',
  x00409216: 'RealWorldValueFirstValueMapped',
  x00409224: 'RealWorldValueIntercept',
  x00409225: 'RealWorldValueSlope',
  x0040a010: 'RelationshipType',
  x0040a027: 'VerifyingOrganization',
  x0040a030: 'VerificationDateTime',
  x0040a032: 'ObservationDateTime',
  x0040a040: 'ValueType',
  x0040a043: 'ConceptNameCodeSequence',
  x0040a050: 'ContinuityOfContent',
  x0040a073: 'VerifyingObserverSequence',
  x0040a075: 'VerifyingObserverName',
  x0040a078: 'AuthorObserverSequence',
  x0040a07a: 'ParticipantSequence',
  x0040a07c: 'CustodialOrganizationSequence',
  x0040a080: 'ParticipationType',
  x0040a082: 'ParticipationDateTime',
  x0040a084: 'ObserverType',
  x0040a088: 'VerifyingObserverIdentCodeSequence',
  x0040a090: 'EquivalentCDADocumentSequence',
  x0040a0b0: 'ReferencedWaveformChannels',
  x0040a120: 'DateTime',
  x0040a121: 'Date',
  x0040a122: 'Time',
  x0040a123: 'PersonName',
  x0040a124: 'UID',
  x0040a130: 'TemporalRangeType',
  x0040a132: 'ReferencedSamplePositions',
  x0040a136: 'ReferencedFrameNumbers',
  x0040a138: 'ReferencedTimeOffsets',
  x0040a13a: 'ReferencedDateTime',
  x0040a160: 'TextValue',
  x0040a168: 'ConceptCodeSequence',
  x0040a170: 'PurposeOfReferenceCodeSequence',
  x0040a180: 'AnnotationGroupNumber',
  x0040a195: 'ModifierCodeSequence',
  x0040a300: 'MeasuredValueSequence',
  x0040a301: 'NumericValueQualifierCodeSequence',
  x0040a30a: 'NumericValue',
  x0040a353: 'AddressTrial',
  x0040a354: 'TelephoneNumberTrial',
  x0040a360: 'PredecessorDocumentsSequence',
  x0040a370: 'ReferencedRequestSequence',
  x0040a372: 'PerformedProcedureCodeSequence',
  x0040a375: 'CurrentRequestedProcEvidenceSeq',
  x0040a385: 'PertinentOtherEvidenceSequence',
  x0040a390: 'HL7StructuredDocumentRefSeq',
  x0040a491: 'CompletionFlag',
  x0040a492: 'CompletionFlagDescription',
  x0040a493: 'VerificationFlag',
  x0040a494: 'ArchiveRequested',
  x0040a496: 'PreliminaryFlag',
  x0040a504: 'ContentTemplateSequence',
  x0040a525: 'IdenticalDocumentsSequence',
  x0040a730: 'ContentSequence',
  x0040b020: 'AnnotationSequence',
  x0040db00: 'TemplateIdentifier',
  x0040db06: 'TemplateVersion',
  x0040db07: 'TemplateLocalVersion',
  x0040db0b: 'TemplateExtensionFlag',
  x0040db0c: 'TemplateExtensionOrganizationUID',
  x0040db0d: 'TemplateExtensionCreatorUID',
  x0040db73: 'ReferencedContentItemIdentifier',
  x0040e001: 'HL7InstanceIdentifier',
  x0040e004: 'HL7DocumentEffectiveTime',
  x0040e006: 'HL7DocumentTypeCodeSequence',
  x0040e010: 'RetrieveURI',
  x0040e011: 'RetrieveLocationUID',
  x00420010: 'DocumentTitle',
  x00420011: 'EncapsulatedDocument',
  x00420012: 'MIMETypeOfEncapsulatedDocument',
  x00420013: 'SourceInstanceSequence',
  x00420014: 'ListOfMIMETypes',
  // x00431001: 'BitmapOfPrescanOptions',
  // x00431002: 'GradientOffsetInX',
  // x00431003: 'GradientOffsetInY',
  // x00431004: 'GradientOffsetInZ',
  // x00431005: 'ImgIsOriginalOrUnoriginal',
  // x00431006: 'NumberOfEPIShots',
  // x00431007: 'ViewsPerSegment',
  // x00431008: 'RespiratoryRateBpm',
  // x00431009: 'RespiratoryTriggerPoint',
  // x0043100a: 'TypeOfReceiverUsed',
  // x0043100b: 'PeakRateOfChangeOfGradientField',
  // x0043100c: 'LimitsInUnitsOfPercent',
  // x0043100d: 'PSDEstimatedLimit',
  // x0043100e: 'PSDEstimatedLimitInTeslaPerSecond',
  // x0043100f: 'Saravghead',
  // x00431010: 'WindowValue',
  // x00431011: 'TotalInputViews',
  // x00431012: 'X-RayChain',
  // x00431013: 'DeconKernelParameters',
  // x00431014: 'CalibrationParameters',
  // x00431015: 'TotalOutputViews',
  // x00431016: 'NumberOfOverranges',
  // x00431017: 'IBHImageScaleFactors',
  // x00431018: 'BBHCoefficients',
  // x00431019: 'NumberOfBBHChainsToBlend',
  // x0043101a: 'StartingChannelNumber',
  // x0043101b: 'PpscanParameters',
  // x0043101c: 'GEImageIntegrity',
  // x0043101d: 'LevelValue',
  // x0043101e: 'DeltaStartTime',
  // x0043101f: 'MaxOverrangesInAView',
  // x00431020: 'AvgOverrangesAllViews',
  // x00431021: 'CorrectedAfterGlowTerms',
  // x00431025: 'ReferenceChannels',
  // x00431026: 'NoViewsRefChansBlocked',
  // x00431027: 'ScanPitchRatio',
  // x00431028: 'UniqueImageIden',
  // x00431029: 'HistogramTables',
  // x0043102a: 'UserDefinedData',
  // x0043102b: 'PrivateScanOptions',
  // x0043102c: 'EffectiveEchoSpacing',
  // x0043102d: 'StringSlopField1',
  // x0043102e: 'StringSlopField2',
  // x0043102f: 'RawDataType',
  // x00431030: 'RawDataType',
  // x00431031: 'RACordOfTargetReconCenter',
  // x00431032: 'RawDataType',
  // x00431033: 'NegScanspacing',
  // x00431034: 'OffsetFrequency',
  // x00431035: 'UserUsageTag',
  // x00431036: 'UserFillMapMSW',
  // x00431037: 'UserFillMapLSW',
  // x00431038: 'User25-48',
  // x00431039: 'SlopInt6-9',
  // x00431040: 'TriggerOnPosition',
  // x00431041: 'DegreeOfRotation',
  // x00431042: 'DASTriggerSource',
  // x00431043: 'DASFpaGain',
  // x00431044: 'DASOutputSource',
  // x00431045: 'DASAdInput',
  // x00431046: 'DASCalMode',
  // x00431047: 'DASCalFrequency',
  // x00431048: 'DASRegXm',
  // x00431049: 'DASAutoZero',
  // x0043104a: 'StartingChannelOfView',
  // x0043104b: 'DASXmPattern',
  // x0043104c: 'TGGCTriggerMode',
  // x0043104d: 'StartScanToXrayOnDelay',
  // x0043104e: 'DurationOfXrayOn',
  // x00431060: 'SlopInt10-17',
  // x00431061: 'ScannerStudyEntityUID',
  // x00431062: 'ScannerStudyID',
  // x0043106f: 'ScannerTableEntry',
  x00440001: 'ProductPackageIdentifier',
  x00440002: 'SubstanceAdministrationApproval',
  x00440003: 'ApprovalStatusFurtherDescription',
  x00440004: 'ApprovalStatusDateTime',
  x00440007: 'ProductTypeCodeSequence',
  x00440008: 'ProductName',
  x00440009: 'ProductDescription',
  x0044000a: 'ProductLotIdentifier',
  x0044000b: 'ProductExpirationDateTime',
  x00440010: 'SubstanceAdministrationDateTime',
  x00440011: 'SubstanceAdministrationNotes',
  x00440012: 'SubstanceAdministrationDeviceID',
  x00440013: 'ProductParameterSequence',
  x00440019: 'SubstanceAdminParameterSeq',
  // x00451001: 'NumberOfMacroRowsInDetector',
  // x00451002: 'MacroWidthAtISOCenter',
  // x00451003: 'DASType',
  // x00451004: 'DASGain',
  // x00451005: 'DASTemperature',
  // x00451006: 'TableDirectionInOrOut',
  // x00451007: 'ZSmoothingFactor',
  // x00451008: 'ViewWeightingMode',
  // x00451009: 'SigmaRowNumberWhichRowsWereUsed',
  // x0045100a: 'MinimumDasValueFoundInTheScanData',
  // x0045100b: 'MaximumOffsetShiftValueUsed',
  // x0045100c: 'NumberOfViewsShifted',
  // x0045100d: 'ZTrackingFlag',
  // x0045100e: 'MeanZError',
  // x0045100f: 'ZTrackingMaximumError',
  // x00451010: 'StartingViewForRow2a',
  // x00451011: 'NumberOfViewsInRow2a',
  // x00451012: 'StartingViewForRow1a',
  // x00451013: 'SigmaMode',
  // x00451014: 'NumberOfViewsInRow1a',
  // x00451015: 'StartingViewForRow2b',
  // x00451016: 'NumberOfViewsInRow2b',
  // x00451017: 'StartingViewForRow1b',
  // x00451018: 'NumberOfViewsInRow1b',
  // x00451019: 'AirFilterCalibrationDate',
  // x0045101a: 'AirFilterCalibrationTime',
  // x0045101b: 'PhantomCalibrationDate',
  // x0045101c: 'PhantomCalibrationTime',
  // x0045101d: 'ZSlopeCalibrationDate',
  // x0045101e: 'ZSlopeCalibrationTime',
  // x0045101f: 'CrosstalkCalibrationDate',
  // x00451020: 'CrosstalkCalibrationTime',
  // x00451021: 'IterboneOptionFlag',
  // x00451022: 'PeristalticFlagOption',
  x00460012: 'LensDescription',
  x00460014: 'RightLensSequence',
  x00460015: 'LeftLensSequence',
  x00460018: 'CylinderSequence',
  x00460028: 'PrismSequence',
  x00460030: 'HorizontalPrismPower',
  x00460032: 'HorizontalPrismBase',
  x00460034: 'VerticalPrismPower',
  x00460036: 'VerticalPrismBase',
  x00460038: 'LensSegmentType',
  x00460040: 'OpticalTransmittance',
  x00460042: 'ChannelWidth',
  x00460044: 'PupilSize',
  x00460046: 'CornealSize',
  x00460060: 'DistancePupillaryDistance',
  x00460062: 'NearPupillaryDistance',
  x00460064: 'OtherPupillaryDistance',
  x00460075: 'RadiusOfCurvature',
  x00460076: 'KeratometricPower',
  x00460077: 'KeratometricAxis',
  x00460092: 'BackgroundColor',
  x00460094: 'Optotype',
  x00460095: 'OptotypePresentation',
  x00460100: 'AddNearSequence',
  x00460101: 'AddIntermediateSequence',
  x00460102: 'AddOtherSequence',
  x00460104: 'AddPower',
  x00460106: 'ViewingDistance',
  x00460125: 'ViewingDistanceType',
  x00460135: 'VisualAcuityModifiers',
  x00460137: 'DecimalVisualAcuity',
  x00460139: 'OptotypeDetailedDefinition',
  x00460146: 'SpherePower',
  x00460147: 'CylinderPower',
  x00500004: 'CalibrationImage',
  x00500010: 'DeviceSequence',
  x00500014: 'DeviceLength',
  x00500015: 'ContainerComponentWidth',
  x00500016: 'DeviceDiameter',
  x00500017: 'DeviceDiameterUnits',
  x00500018: 'DeviceVolume',
  x00500019: 'InterMarkerDistance',
  x0050001b: 'ContainerComponentID',
  x00500020: 'DeviceDescription',
  x00540010: 'EnergyWindowVector',
  x00540011: 'NumberOfEnergyWindows',
  x00540012: 'EnergyWindowInformationSequence',
  x00540013: 'EnergyWindowRangeSequence',
  x00540014: 'EnergyWindowLowerLimit',
  x00540015: 'EnergyWindowUpperLimit',
  x00540016: 'RadiopharmaceuticalInformationSeq',
  x00540017: 'ResidualSyringeCounts',
  x00540018: 'EnergyWindowName',
  x00540020: 'DetectorVector',
  x00540021: 'NumberOfDetectors',
  x00540022: 'DetectorInformationSequence',
  x00540030: 'PhaseVector',
  x00540031: 'NumberOfPhases',
  x00540032: 'PhaseInformationSequence',
  x00540033: 'NumberOfFramesInPhase',
  x00540036: 'PhaseDelay',
  x00540038: 'PauseBetweenFrames',
  x00540039: 'PhaseDescription',
  x00540050: 'RotationVector',
  x00540051: 'NumberOfRotations',
  x00540052: 'RotationInformationSequence',
  x00540053: 'NumberOfFramesInRotation',
  x00540060: 'RRIntervalVector',
  x00540061: 'NumberOfRRIntervals',
  x00540062: 'GatedInformationSequence',
  x00540063: 'DataInformationSequence',
  x00540070: 'TimeSlotVector',
  x00540071: 'NumberOfTimeSlots',
  x00540072: 'TimeSlotInformationSequence',
  x00540073: 'TimeSlotTime',
  x00540080: 'SliceVector',
  x00540081: 'NumberOfSlices',
  x00540090: 'AngularViewVector',
  x00540100: 'TimeSliceVector',
  x00540101: 'NumberOfTimeSlices',
  x00540200: 'StartAngle',
  x00540202: 'TypeOfDetectorMotion',
  x00540210: 'TriggerVector',
  x00540211: 'NumberOfTriggersInPhase',
  x00540220: 'ViewCodeSequence',
  x00540222: 'ViewModifierCodeSequence',
  x00540300: 'RadionuclideCodeSequence',
  x00540302: 'AdministrationRouteCodeSequence',
  x00540304: 'RadiopharmaceuticalCodeSequence',
  x00540306: 'CalibrationDataSequence',
  x00540308: 'EnergyWindowNumber',
  x00540400: 'ImageID',
  x00540410: 'PatientOrientationCodeSequence',
  x00540412: 'PatientOrientationModifierCodeSeq',
  x00540414: 'PatientGantryRelationshipCodeSeq',
  x00540500: 'SliceProgressionDirection',
  x00541000: 'SeriesType',
  x00541001: 'Units',
  x00541002: 'CountsSource',
  x00541004: 'ReprojectionMethod',
  x00541100: 'RandomsCorrectionMethod',
  x00541101: 'AttenuationCorrectionMethod',
  x00541102: 'DecayCorrection',
  x00541103: 'ReconstructionMethod',
  x00541104: 'DetectorLinesOfResponseUsed',
  x00541105: 'ScatterCorrectionMethod',
  x00541200: 'AxialAcceptance',
  x00541201: 'AxialMash',
  x00541202: 'TransverseMash',
  x00541203: 'DetectorElementSize',
  x00541210: 'CoincidenceWindowWidth',
  x00541220: 'SecondaryCountsType',
  x00541300: 'FrameReferenceTime',
  x00541310: 'PrimaryCountsAccumulated',
  x00541311: 'SecondaryCountsAccumulated',
  x00541320: 'SliceSensitivityFactor',
  x00541321: 'DecayFactor',
  x00541322: 'DoseCalibrationFactor',
  x00541323: 'ScatterFractionFactor',
  x00541324: 'DeadTimeFactor',
  x00541330: 'ImageIndex',
  x00541400: 'CountsIncluded',
  x00541401: 'DeadTimeCorrectionFlag',
  x00603000: 'HistogramSequence',
  x00603002: 'HistogramNumberOfBins',
  x00603004: 'HistogramFirstBinValue',
  x00603006: 'HistogramLastBinValue',
  x00603008: 'HistogramBinWidth',
  x00603010: 'HistogramExplanation',
  x00603020: 'HistogramData',
  x00620001: 'SegmentationType',
  x00620002: 'SegmentSequence',
  x00620003: 'SegmentedPropertyCategoryCodeSeq',
  x00620004: 'SegmentNumber',
  x00620005: 'SegmentLabel',
  x00620006: 'SegmentDescription',
  x00620008: 'SegmentAlgorithmType',
  x00620009: 'SegmentAlgorithmName',
  x0062000a: 'SegmentIdentificationSequence',
  x0062000b: 'ReferencedSegmentNumber',
  x0062000c: 'RecommendedDisplayGrayscaleValue',
  x0062000d: 'RecommendedDisplayCIELabValue',
  x0062000e: 'MaximumFractionalValue',
  x0062000f: 'SegmentedPropertyTypeCodeSequence',
  x00620010: 'SegmentationFractionalType',
  x00640002: 'DeformableRegistrationSequence',
  x00640003: 'SourceFrameOfReferenceUID',
  x00640005: 'DeformableRegistrationGridSequence',
  x00640007: 'GridDimensions',
  x00640008: 'GridResolution',
  x00640009: 'VectorGridData',
  x0064000f: 'PreDeformationMatrixRegistSeq',
  x00640010: 'PostDeformationMatrixRegistSeq',
  x00660001: 'NumberOfSurfaces',
  x00660002: 'SurfaceSequence',
  x00660003: 'SurfaceNumber',
  x00660004: 'SurfaceComments',
  x00660009: 'SurfaceProcessing',
  x0066000a: 'SurfaceProcessingRatio',
  x0066000e: 'FiniteVolume',
  x00660010: 'Manifold',
  x00660011: 'SurfacePointsSequence',
  x00660015: 'NumberOfSurfacePoints',
  x00660016: 'PointCoordinatesData',
  x00660017: 'PointPositionAccuracy',
  x00660018: 'MeanPointDistance',
  x00660019: 'MaximumPointDistance',
  x0066001b: 'AxisOfRotation',
  x0066001c: 'CenterOfRotation',
  x0066001e: 'NumberOfVectors',
  x0066001f: 'VectorDimensionality',
  x00660020: 'VectorAccuracy',
  x00660021: 'VectorCoordinateData',
  x00660023: 'TrianglePointIndexList',
  x00660024: 'EdgePointIndexList',
  x00660025: 'VertexPointIndexList',
  x00660026: 'TriangleStripSequence',
  x00660027: 'TriangleFanSequence',
  x00660028: 'LineSequence',
  x00660029: 'PrimitivePointIndexList',
  x0066002a: 'SurfaceCount',
  x0066002f: 'AlgorithmFamilyCodeSequ',
  x00660031: 'AlgorithmVersion',
  x00660032: 'AlgorithmParameters',
  x00660034: 'FacetSequence',
  x00660036: 'AlgorithmName',
  x00700001: 'GraphicAnnotationSequence',
  x00700002: 'GraphicLayer',
  x00700003: 'BoundingBoxAnnotationUnits',
  x00700004: 'AnchorPointAnnotationUnits',
  x00700005: 'GraphicAnnotationUnits',
  x00700006: 'UnformattedTextValue',
  x00700008: 'TextObjectSequence',
  x00700009: 'GraphicObjectSequence',
  x00700010: 'BoundingBoxTopLeftHandCorner',
  x00700011: 'BoundingBoxBottomRightHandCorner',
  x00700012: 'BoundingBoxTextHorizJustification',
  x00700014: 'AnchorPoint',
  x00700015: 'AnchorPointVisibility',
  x00700020: 'GraphicDimensions',
  x00700021: 'NumberOfGraphicPoints',
  x00700022: 'GraphicData',
  x00700023: 'GraphicType',
  x00700024: 'GraphicFilled',
  x00700040: 'ImageRotationRetired',
  x00700041: 'ImageHorizontalFlip',
  x00700042: 'ImageRotation',
  x00700050: 'DisplayedAreaTopLeftTrial',
  x00700051: 'DisplayedAreaBottomRightTrial',
  x00700052: 'DisplayedAreaTopLeft',
  x00700053: 'DisplayedAreaBottomRight',
  x0070005a: 'DisplayedAreaSelectionSequence',
  x00700060: 'GraphicLayerSequence',
  x00700062: 'GraphicLayerOrder',
  x00700066: 'GraphicLayerRecDisplayGraysclValue',
  x00700067: 'GraphicLayerRecDisplayRGBValue',
  x00700068: 'GraphicLayerDescription',
  x00700080: 'ContentLabel',
  x00700081: 'ContentDescription',
  x00700082: 'PresentationCreationDate',
  x00700083: 'PresentationCreationTime',
  x00700084: 'ContentCreatorName',
  x00700086: 'ContentCreatorIDCodeSequence',
  x00700100: 'PresentationSizeMode',
  x00700101: 'PresentationPixelSpacing',
  x00700102: 'PresentationPixelAspectRatio',
  x00700103: 'PresentationPixelMagRatio',
  x00700306: 'ShapeType',
  x00700308: 'RegistrationSequence',
  x00700309: 'MatrixRegistrationSequence',
  x0070030a: 'MatrixSequence',
  x0070030c: 'FrameOfRefTransformationMatrixType',
  x0070030d: 'RegistrationTypeCodeSequence',
  x0070030f: 'FiducialDescription',
  x00700310: 'FiducialIdentifier',
  x00700311: 'FiducialIdentifierCodeSequence',
  x00700312: 'ContourUncertaintyRadius',
  x00700314: 'UsedFiducialsSequence',
  x00700318: 'GraphicCoordinatesDataSequence',
  x0070031a: 'FiducialUID',
  x0070031c: 'FiducialSetSequence',
  x0070031e: 'FiducialSequence',
  x00700401: 'GraphicLayerRecomDisplayCIELabVal',
  x00700402: 'BlendingSequence',
  x00700403: 'RelativeOpacity',
  x00700404: 'ReferencedSpatialRegistrationSeq',
  x00700405: 'BlendingPosition',
  x00720002: 'HangingProtocolName',
  x00720004: 'HangingProtocolDescription',
  x00720006: 'HangingProtocolLevel',
  x00720008: 'HangingProtocolCreator',
  x0072000a: 'HangingProtocolCreationDateTime',
  x0072000c: 'HangingProtocolDefinitionSequence',
  x0072000e: 'HangingProtocolUserIDCodeSequence',
  x00720010: 'HangingProtocolUserGroupName',
  x00720012: 'SourceHangingProtocolSequence',
  x00720014: 'NumberOfPriorsReferenced',
  x00720020: 'ImageSetsSequence',
  x00720022: 'ImageSetSelectorSequence',
  x00720024: 'ImageSetSelectorUsageFlag',
  x00720026: 'SelectorAttribute',
  x00720028: 'SelectorValueNumber',
  x00720030: 'TimeBasedImageSetsSequence',
  x00720032: 'ImageSetNumber',
  x00720034: 'ImageSetSelectorCategory',
  x00720038: 'RelativeTime',
  x0072003a: 'RelativeTimeUnits',
  x0072003c: 'AbstractPriorValue',
  x0072003e: 'AbstractPriorCodeSequence',
  x00720040: 'ImageSetLabel',
  x00720050: 'SelectorAttributeVR',
  x00720052: 'SelectorSequencePointer',
  x00720054: 'SelectorSeqPointerPrivateCreator',
  x00720056: 'SelectorAttributePrivateCreator',
  x00720060: 'SelectorATValue',
  x00720062: 'SelectorCSValue',
  x00720064: 'SelectorISValue',
  x00720066: 'SelectorLOValue',
  x00720068: 'SelectorLTValue',
  x0072006a: 'SelectorPNValue',
  x0072006c: 'SelectorSHValue',
  x0072006e: 'SelectorSTValue',
  x00720070: 'SelectorUTValue',
  x00720072: 'SelectorDSValue',
  x00720074: 'SelectorFDValue',
  x00720076: 'SelectorFLValue',
  x00720078: 'SelectorULValue',
  x0072007a: 'SelectorUSValue',
  x0072007c: 'SelectorSLValue',
  x0072007e: 'SelectorSSValue',
  x00720080: 'SelectorCodeSequenceValue',
  x00720100: 'NumberOfScreens',
  x00720102: 'NominalScreenDefinitionSequence',
  x00720104: 'NumberOfVerticalPixels',
  x00720106: 'NumberOfHorizontalPixels',
  x00720108: 'DisplayEnvironmentSpatialPosition',
  x0072010a: 'ScreenMinimumGrayscaleBitDepth',
  x0072010c: 'ScreenMinimumColorBitDepth',
  x0072010e: 'ApplicationMaximumRepaintTime',
  x00720200: 'DisplaySetsSequence',
  x00720202: 'DisplaySetNumber',
  x00720203: 'DisplaySetLabel',
  x00720204: 'DisplaySetPresentationGroup',
  x00720206: 'DisplaySetPresentationGroupDescr',
  x00720208: 'PartialDataDisplayHandling',
  x00720210: 'SynchronizedScrollingSequence',
  x00720212: 'DisplaySetScrollingGroup',
  x00720214: 'NavigationIndicatorSequence',
  x00720216: 'NavigationDisplaySet',
  x00720218: 'ReferenceDisplaySets',
  x00720300: 'ImageBoxesSequence',
  x00720302: 'ImageBoxNumber',
  x00720304: 'ImageBoxLayoutType',
  x00720306: 'ImageBoxTileHorizontalDimension',
  x00720308: 'ImageBoxTileVerticalDimension',
  x00720310: 'ImageBoxScrollDirection',
  x00720312: 'ImageBoxSmallScrollType',
  x00720314: 'ImageBoxSmallScrollAmount',
  x00720316: 'ImageBoxLargeScrollType',
  x00720318: 'ImageBoxLargeScrollAmount',
  x00720320: 'ImageBoxOverlapPriority',
  x00720330: 'CineRelativeToRealTime',
  x00720400: 'FilterOperationsSequence',
  x00720402: 'FilterByCategory',
  x00720404: 'FilterByAttributePresence',
  x00720406: 'FilterByOperator',
  x00720432: 'SynchronizedImageBoxList',
  x00720434: 'TypeOfSynchronization',
  x00720500: 'BlendingOperationType',
  x00720510: 'ReformattingOperationType',
  x00720512: 'ReformattingThickness',
  x00720514: 'ReformattingInterval',
  x00720516: 'ReformattingOpInitialViewDir',
  x00720520: 'RenderingType3D',
  x00720600: 'SortingOperationsSequence',
  x00720602: 'SortByCategory',
  x00720604: 'SortingDirection',
  x00720700: 'DisplaySetPatientOrientation',
  x00720702: 'VOIType',
  x00720704: 'PseudoColorType',
  x00720706: 'ShowGrayscaleInverted',
  x00720710: 'ShowImageTrueSizeFlag',
  x00720712: 'ShowGraphicAnnotationFlag',
  x00720714: 'ShowPatientDemographicsFlag',
  x00720716: 'ShowAcquisitionTechniquesFlag',
  x00720717: 'DisplaySetHorizontalJustification',
  x00720718: 'DisplaySetVerticalJustification',
  x00741000: 'UnifiedProcedureStepState',
  x00741002: 'UPSProgressInformationSequence',
  x00741004: 'UnifiedProcedureStepProgress',
  x00741006: 'UnifiedProcedureStepProgressDescr',
  x00741008: 'UnifiedProcedureStepComURISeq',
  x0074100a: 'ContactURI',
  x0074100c: 'ContactDisplayName',
  x00741020: 'BeamTaskSequence',
  x00741022: 'BeamTaskType',
  x00741024: 'BeamOrderIndex',
  x00741030: 'DeliveryVerificationImageSequence',
  x00741032: 'VerificationImageTiming',
  x00741034: 'DoubleExposureFlag',
  x00741036: 'DoubleExposureOrdering',
  x00741038: 'DoubleExposureMeterset',
  x0074103a: 'DoubleExposureFieldDelta',
  x00741040: 'RelatedReferenceRTImageSequence',
  x00741042: 'GeneralMachineVerificationSequence',
  x00741044: 'ConventionalMachineVerificationSeq',
  x00741046: 'IonMachineVerificationSequence',
  x00741048: 'FailedAttributesSequence',
  x0074104a: 'OverriddenAttributesSequence',
  x0074104c: 'ConventionalControlPointVerifySeq',
  x0074104e: 'IonControlPointVerificationSeq',
  x00741050: 'AttributeOccurrenceSequence',
  x00741052: 'AttributeOccurrencePointer',
  x00741054: 'AttributeItemSelector',
  x00741056: 'AttributeOccurrencePrivateCreator',
  x00741200: 'ScheduledProcedureStepPriority',
  x00741202: 'StudyListLabel',
  x00741204: 'ProcedureStepLabel',
  x00741210: 'ScheduledProcessingParametersSeq',
  x00741212: 'PerformedProcessingParametersSeq',
  x00741216: 'UPSPerformedProcedureSequence',
  x00741220: 'RelatedProcedureStepSequence',
  x00741222: 'ProcedureStepRelationshipType',
  x00741230: 'DeletionLock',
  x00741234: 'ReceivingAE',
  x00741236: 'RequestingAE',
  x00741238: 'ReasonForCancellation',
  x00741242: 'SCPStatus',
  x00741244: 'SubscriptionListStatus',
  x00741246: 'UPSListStatus',
  x00880130: 'StorageMediaFileSetID',
  x00880140: 'StorageMediaFileSetUID',
  x00880200: 'IconImageSequence',
  x00880904: 'TopicTitle',
  x00880906: 'TopicSubject',
  x00880910: 'TopicAuthor',
  x00880912: 'TopicKeywords',
  x01000410: 'SOPInstanceStatus',
  x01000420: 'SOPAuthorizationDateAndTime',
  x01000424: 'SOPAuthorizationComment',
  x01000426: 'AuthorizationEquipmentCertNumber',
  x04000005: 'MACIDNumber',
  x04000010: 'MACCalculationTransferSyntaxUID',
  x04000015: 'MACAlgorithm',
  x04000020: 'DataElementsSigned',
  x04000100: 'DigitalSignatureUID',
  x04000105: 'DigitalSignatureDateTime',
  x04000110: 'CertificateType',
  x04000115: 'CertificateOfSigner',
  x04000120: 'Signature',
  x04000305: 'CertifiedTimestampType',
  x04000310: 'CertifiedTimestamp',
  x04000401: 'DigitalSignaturePurposeCodeSeq',
  x04000402: 'ReferencedDigitalSignatureSeq',
  x04000403: 'ReferencedSOPInstanceMACSeq',
  x04000404: 'MAC',
  x04000500: 'EncryptedAttributesSequence',
  x04000510: 'EncryptedContentTransferSyntaxUID',
  x04000520: 'EncryptedContent',
  x04000550: 'ModifiedAttributesSequence',
  x04000561: 'OriginalAttributesSequence',
  x04000562: 'AttributeModificationDateTime',
  x04000563: 'ModifyingSystem',
  x04000564: 'SourceOfPreviousValues',
  x04000565: 'ReasonForTheAttributeModification',
  x1000xxx0: 'EscapeTriplet',
  x1000xxx1: 'RunLengthTriplet',
  x1000xxx2: 'HuffmanTableSize',
  x1000xxx3: 'HuffmanTableTriplet',
  x1000xxx4: 'ShiftTableSize',
  x1000xxx5: 'ShiftTableTriplet',
  x1010xxxx: 'ZonalMap',
  x20000010: 'NumberOfCopies',
  x2000001e: 'PrinterConfigurationSequence',
  x20000020: 'PrintPriority',
  x20000030: 'MediumType',
  x20000040: 'FilmDestination',
  x20000050: 'FilmSessionLabel',
  x20000060: 'MemoryAllocation',
  x20000061: 'MaximumMemoryAllocation',
  x20000062: 'ColorImagePrintingFlag',
  x20000063: 'CollationFlag',
  x20000065: 'AnnotationFlag',
  x20000067: 'ImageOverlayFlag',
  x20000069: 'PresentationLUTFlag',
  x2000006a: 'ImageBoxPresentationLUTFlag',
  x200000a0: 'MemoryBitDepth',
  x200000a1: 'PrintingBitDepth',
  x200000a2: 'MediaInstalledSequence',
  x200000a4: 'OtherMediaAvailableSequence',
  x200000a8: 'SupportedImageDisplayFormatSeq',
  x20000500: 'ReferencedFilmBoxSequence',
  x20000510: 'ReferencedStoredPrintSequence',
  x20100010: 'ImageDisplayFormat',
  x20100030: 'AnnotationDisplayFormatID',
  x20100040: 'FilmOrientation',
  x20100050: 'FilmSizeID',
  x20100052: 'PrinterResolutionID',
  x20100054: 'DefaultPrinterResolutionID',
  x20100060: 'MagnificationType',
  x20100080: 'SmoothingType',
  x201000a6: 'DefaultMagnificationType',
  x201000a7: 'OtherMagnificationTypesAvailable',
  x201000a8: 'DefaultSmoothingType',
  x201000a9: 'OtherSmoothingTypesAvailable',
  x20100100: 'BorderDensity',
  x20100110: 'EmptyImageDensity',
  x20100120: 'MinDensity',
  x20100130: 'MaxDensity',
  x20100140: 'Trim',
  x20100150: 'ConfigurationInformation',
  x20100152: 'ConfigurationInformationDescr',
  x20100154: 'MaximumCollatedFilms',
  x2010015e: 'Illumination',
  x20100160: 'ReflectedAmbientLight',
  x20100376: 'PrinterPixelSpacing',
  x20100500: 'ReferencedFilmSessionSequence',
  x20100510: 'ReferencedImageBoxSequence',
  x20100520: 'ReferencedBasicAnnotationBoxSeq',
  x20200010: 'ImageBoxPosition',
  x20200020: 'Polarity',
  x20200030: 'RequestedImageSize',
  x20200040: 'RequestedDecimate-CropBehavior',
  x20200050: 'RequestedResolutionID',
  x202000a0: 'RequestedImageSizeFlag',
  x202000a2: 'DecimateCropResult',
  x20200110: 'BasicGrayscaleImageSequence',
  x20200111: 'BasicColorImageSequence',
  x20200130: 'ReferencedImageOverlayBoxSequence',
  x20200140: 'ReferencedVOILUTBoxSequence',
  x20300010: 'AnnotationPosition',
  x20300020: 'TextString',
  x20400010: 'ReferencedOverlayPlaneSequence',
  x20400011: 'ReferencedOverlayPlaneGroups',
  x20400020: 'OverlayPixelDataSequence',
  x20400060: 'OverlayMagnificationType',
  x20400070: 'OverlaySmoothingType',
  x20400072: 'OverlayOrImageMagnification',
  x20400074: 'MagnifyToNumberOfColumns',
  x20400080: 'OverlayForegroundDensity',
  x20400082: 'OverlayBackgroundDensity',
  x20400090: 'OverlayMode',
  x20400100: 'ThresholdDensity',
  x20400500: 'ReferencedImageBoxSequenceRetired',
  x20500010: 'PresentationLUTSequence',
  x20500020: 'PresentationLUTShape',
  x20500500: 'ReferencedPresentationLUTSequence',
  x21000010: 'PrintJobID',
  x21000020: 'ExecutionStatus',
  x21000030: 'ExecutionStatusInfo',
  x21000040: 'CreationDate',
  x21000050: 'CreationTime',
  x21000070: 'Originator',
  x21000140: 'DestinationAE',
  x21000160: 'OwnerID',
  x21000170: 'NumberOfFilms',
  x21000500: 'ReferencedPrintJobSequencePullStoredPrint',
  x21100010: 'PrinterStatus',
  x21100020: 'PrinterStatusInfo',
  x21100030: 'PrinterName',
  x21100099: 'PrintQueueID',
  x21200010: 'QueueStatus',
  x21200050: 'PrintJobDescriptionSequence',
  x21200070: 'ReferencedPrintJobSequence',
  x21300010: 'PrintManagementCapabilitiesSeq',
  x21300015: 'PrinterCharacteristicsSequence',
  x21300030: 'FilmBoxContentSequence',
  x21300040: 'ImageBoxContentSequence',
  x21300050: 'AnnotationContentSequence',
  x21300060: 'ImageOverlayBoxContentSequence',
  x21300080: 'PresentationLUTContentSequence',
  x213000a0: 'ProposedStudySequence',
  x213000c0: 'OriginalImageSequence',
  x22000001: 'LabelFromInfoExtractedFromInstance',
  x22000002: 'LabelText',
  x22000003: 'LabelStyleSelection',
  x22000004: 'MediaDisposition',
  x22000005: 'BarcodeValue',
  x22000006: 'BarcodeSymbology',
  x22000007: 'AllowMediaSplitting',
  x22000008: 'IncludeNonDICOMObjects',
  x22000009: 'IncludeDisplayApplication',
  x2200000a: 'SaveCompInstancesAfterMediaCreate',
  x2200000b: 'TotalNumberMediaPiecesCreated',
  x2200000c: 'RequestedMediaApplicationProfile',
  x2200000d: 'ReferencedStorageMediaSequence',
  x2200000e: 'FailureAttributes',
  x2200000f: 'AllowLossyCompression',
  x22000020: 'RequestPriority',
  x30020002: 'RTImageLabel',
  x30020003: 'RTImageName',
  x30020004: 'RTImageDescription',
  x3002000a: 'ReportedValuesOrigin',
  x3002000c: 'RTImagePlane',
  x3002000d: 'XRayImageReceptorTranslation',
  x3002000e: 'XRayImageReceptorAngle',
  x30020010: 'RTImageOrientation',
  x30020011: 'ImagePlanePixelSpacing',
  x30020012: 'RTImagePosition',
  x30020020: 'RadiationMachineName',
  x30020022: 'RadiationMachineSAD',
  x30020024: 'RadiationMachineSSD',
  x30020026: 'RTImageSID',
  x30020028: 'SourceToReferenceObjectDistance',
  x30020029: 'FractionNumber',
  x30020030: 'ExposureSequence',
  x30020032: 'MetersetExposure',
  x30020034: 'DiaphragmPosition',
  x30020040: 'FluenceMapSequence',
  x30020041: 'FluenceDataSource',
  x30020042: 'FluenceDataScale',
  x30020051: 'FluenceMode',
  x30020052: 'FluenceModeID',
  x30040001: 'DVHType',
  x30040002: 'DoseUnits',
  x30040004: 'DoseType',
  x30040006: 'DoseComment',
  x30040008: 'NormalizationPoint',
  x3004000a: 'DoseSummationType',
  x3004000c: 'GridFrameOffsetVector',
  x3004000e: 'DoseGridScaling',
  x30040010: 'RTDoseROISequence',
  x30040012: 'DoseValue',
  x30040014: 'TissueHeterogeneityCorrection',
  x30040040: 'DVHNormalizationPoint',
  x30040042: 'DVHNormalizationDoseValue',
  x30040050: 'DVHSequence',
  x30040052: 'DVHDoseScaling',
  x30040054: 'DVHVolumeUnits',
  x30040056: 'DVHNumberOfBins',
  x30040058: 'DVHData',
  x30040060: 'DVHReferencedROISequence',
  x30040062: 'DVHROIContributionType',
  x30040070: 'DVHMinimumDose',
  x30040072: 'DVHMaximumDose',
  x30040074: 'DVHMeanDose',
  x30060002: 'StructureSetLabel',
  x30060004: 'StructureSetName',
  x30060006: 'StructureSetDescription',
  x30060008: 'StructureSetDate',
  x30060009: 'StructureSetTime',
  x30060010: 'ReferencedFrameOfReferenceSequence',
  x30060012: 'RTReferencedStudySequence',
  x30060014: 'RTReferencedSeriesSequence',
  x30060016: 'ContourImageSequence',
  x30060020: 'StructureSetROISequence',
  x30060022: 'ROINumber',
  x30060024: 'ReferencedFrameOfReferenceUID',
  x30060026: 'ROIName',
  x30060028: 'ROIDescription',
  x3006002a: 'ROIDisplayColor',
  x3006002c: 'ROIVolume',
  x30060030: 'RTRelatedROISequence',
  x30060033: 'RTROIRelationship',
  x30060036: 'ROIGenerationAlgorithm',
  x30060038: 'ROIGenerationDescription',
  x30060039: 'ROIContourSequence',
  x30060040: 'ContourSequence',
  x30060042: 'ContourGeometricType',
  x30060044: 'ContourSlabThickness',
  x30060045: 'ContourOffsetVector',
  x30060046: 'NumberOfContourPoints',
  x30060048: 'ContourNumber',
  x30060049: 'AttachedContours',
  x30060050: 'ContourData',
  x30060080: 'RTROIObservationsSequence',
  x30060082: 'ObservationNumber',
  x30060084: 'ReferencedROINumber',
  x30060085: 'ROIObservationLabel',
  x30060086: 'RTROIIdentificationCodeSequence',
  x30060088: 'ROIObservationDescription',
  x300600a0: 'RelatedRTROIObservationsSequence',
  x300600a4: 'RTROIInterpretedType',
  x300600a6: 'ROIInterpreter',
  x300600b0: 'ROIPhysicalPropertiesSequence',
  x300600b2: 'ROIPhysicalProperty',
  x300600b4: 'ROIPhysicalPropertyValue',
  x300600b6: 'ROIElementalCompositionSequence',
  x300600b7: 'ROIElementalCompAtomicNumber',
  x300600b8: 'ROIElementalCompAtomicMassFraction',
  x300600c0: 'FrameOfReferenceRelationshipSeq',
  x300600c2: 'RelatedFrameOfReferenceUID',
  x300600c4: 'FrameOfReferenceTransformType',
  x300600c6: 'FrameOfReferenceTransformMatrix',
  x300600c8: 'FrameOfReferenceTransformComment',
  x30080010: 'MeasuredDoseReferenceSequence',
  x30080012: 'MeasuredDoseDescription',
  x30080014: 'MeasuredDoseType',
  x30080016: 'MeasuredDoseValue',
  x30080020: 'TreatmentSessionBeamSequence',
  x30080021: 'TreatmentSessionIonBeamSequence',
  x30080022: 'CurrentFractionNumber',
  x30080024: 'TreatmentControlPointDate',
  x30080025: 'TreatmentControlPointTime',
  x3008002a: 'TreatmentTerminationStatus',
  x3008002b: 'TreatmentTerminationCode',
  x3008002c: 'TreatmentVerificationStatus',
  x30080030: 'ReferencedTreatmentRecordSequence',
  x30080032: 'SpecifiedPrimaryMeterset',
  x30080033: 'SpecifiedSecondaryMeterset',
  x30080036: 'DeliveredPrimaryMeterset',
  x30080037: 'DeliveredSecondaryMeterset',
  x3008003a: 'SpecifiedTreatmentTime',
  x3008003b: 'DeliveredTreatmentTime',
  x30080040: 'ControlPointDeliverySequence',
  x30080041: 'IonControlPointDeliverySequence',
  x30080042: 'SpecifiedMeterset',
  x30080044: 'DeliveredMeterset',
  x30080045: 'MetersetRateSet',
  x30080046: 'MetersetRateDelivered',
  x30080047: 'ScanSpotMetersetsDelivered',
  x30080048: 'DoseRateDelivered',
  x30080050: 'TreatmentSummaryCalcDoseRefSeq',
  x30080052: 'CumulativeDoseToDoseReference',
  x30080054: 'FirstTreatmentDate',
  x30080056: 'MostRecentTreatmentDate',
  x3008005a: 'NumberOfFractionsDelivered',
  x30080060: 'OverrideSequence',
  x30080061: 'ParameterSequencePointer',
  x30080062: 'OverrideParameterPointer',
  x30080063: 'ParameterItemIndex',
  x30080064: 'MeasuredDoseReferenceNumber',
  x30080065: 'ParameterPointer',
  x30080066: 'OverrideReason',
  x30080068: 'CorrectedParameterSequence',
  x3008006a: 'CorrectionValue',
  x30080070: 'CalculatedDoseReferenceSequence',
  x30080072: 'CalculatedDoseReferenceNumber',
  x30080074: 'CalculatedDoseReferenceDescription',
  x30080076: 'CalculatedDoseReferenceDoseValue',
  x30080078: 'StartMeterset',
  x3008007a: 'EndMeterset',
  x30080080: 'ReferencedMeasuredDoseReferenceSeq',
  x30080082: 'ReferencedMeasuredDoseReferenceNum',
  x30080090: 'ReferencedCalculatedDoseRefSeq',
  x30080092: 'ReferencedCalculatedDoseRefNumber',
  x300800a0: 'BeamLimitingDeviceLeafPairsSeq',
  x300800b0: 'RecordedWedgeSequence',
  x300800c0: 'RecordedCompensatorSequence',
  x300800d0: 'RecordedBlockSequence',
  x300800e0: 'TreatmentSummaryMeasuredDoseRefSeq',
  x300800f0: 'RecordedSnoutSequence',
  x300800f2: 'RecordedRangeShifterSequence',
  x300800f4: 'RecordedLateralSpreadingDeviceSeq',
  x300800f6: 'RecordedRangeModulatorSequence',
  x30080100: 'RecordedSourceSequence',
  x30080105: 'SourceSerialNumber',
  x30080110: 'TreatmentSessionAppSetupSeq',
  x30080116: 'ApplicationSetupCheck',
  x30080120: 'RecordedBrachyAccessoryDeviceSeq',
  x30080122: 'ReferencedBrachyAccessoryDeviceNum',
  x30080130: 'RecordedChannelSequence',
  x30080132: 'SpecifiedChannelTotalTime',
  x30080134: 'DeliveredChannelTotalTime',
  x30080136: 'SpecifiedNumberOfPulses',
  x30080138: 'DeliveredNumberOfPulses',
  x3008013a: 'SpecifiedPulseRepetitionInterval',
  x3008013c: 'DeliveredPulseRepetitionInterval',
  x30080140: 'RecordedSourceApplicatorSequence',
  x30080142: 'ReferencedSourceApplicatorNumber',
  x30080150: 'RecordedChannelShieldSequence',
  x30080152: 'ReferencedChannelShieldNumber',
  x30080160: 'BrachyControlPointDeliveredSeq',
  x30080162: 'SafePositionExitDate',
  x30080164: 'SafePositionExitTime',
  x30080166: 'SafePositionReturnDate',
  x30080168: 'SafePositionReturnTime',
  x30080200: 'CurrentTreatmentStatus',
  x30080202: 'TreatmentStatusComment',
  x30080220: 'FractionGroupSummarySequence',
  x30080223: 'ReferencedFractionNumber',
  x30080224: 'FractionGroupType',
  x30080230: 'BeamStopperPosition',
  x30080240: 'FractionStatusSummarySequence',
  x30080250: 'TreatmentDate',
  x30080251: 'TreatmentTime',
  x300a0002: 'RTPlanLabel',
  x300a0003: 'RTPlanName',
  x300a0004: 'RTPlanDescription',
  x300a0006: 'RTPlanDate',
  x300a0007: 'RTPlanTime',
  x300a0009: 'TreatmentProtocols',
  x300a000a: 'PlanIntent',
  x300a000b: 'TreatmentSites',
  x300a000c: 'RTPlanGeometry',
  x300a000e: 'PrescriptionDescription',
  x300a0010: 'DoseReferenceSequence',
  x300a0012: 'DoseReferenceNumber',
  x300a0013: 'DoseReferenceUID',
  x300a0014: 'DoseReferenceStructureType',
  x300a0015: 'NominalBeamEnergyUnit',
  x300a0016: 'DoseReferenceDescription',
  x300a0018: 'DoseReferencePointCoordinates',
  x300a001a: 'NominalPriorDose',
  x300a0020: 'DoseReferenceType',
  x300a0021: 'ConstraintWeight',
  x300a0022: 'DeliveryWarningDose',
  x300a0023: 'DeliveryMaximumDose',
  x300a0025: 'TargetMinimumDose',
  x300a0026: 'TargetPrescriptionDose',
  x300a0027: 'TargetMaximumDose',
  x300a0028: 'TargetUnderdoseVolumeFraction',
  x300a002a: 'OrganAtRiskFullVolumeDose',
  x300a002b: 'OrganAtRiskLimitDose',
  x300a002c: 'OrganAtRiskMaximumDose',
  x300a002d: 'OrganAtRiskOverdoseVolumeFraction',
  x300a0040: 'ToleranceTableSequence',
  x300a0042: 'ToleranceTableNumber',
  x300a0043: 'ToleranceTableLabel',
  x300a0044: 'GantryAngleTolerance',
  x300a0046: 'BeamLimitingDeviceAngleTolerance',
  x300a0048: 'BeamLimitingDeviceToleranceSeq',
  x300a004a: 'BeamLimitingDevicePositionTol',
  x300a004b: 'SnoutPositionTolerance',
  x300a004c: 'PatientSupportAngleTolerance',
  x300a004e: 'TableTopEccentricAngleTolerance',
  x300a004f: 'TableTopPitchAngleTolerance',
  x300a0050: 'TableTopRollAngleTolerance',
  x300a0051: 'TableTopVerticalPositionTolerance',
  x300a0052: 'TableTopLongitudinalPositionTol',
  x300a0053: 'TableTopLateralPositionTolerance',
  x300a0055: 'RTPlanRelationship',
  x300a0070: 'FractionGroupSequence',
  x300a0071: 'FractionGroupNumber',
  x300a0072: 'FractionGroupDescription',
  x300a0078: 'NumberOfFractionsPlanned',
  x300a0079: 'NumberFractionPatternDigitsPerDay',
  x300a007a: 'RepeatFractionCycleLength',
  x300a007b: 'FractionPattern',
  x300a0080: 'NumberOfBeams',
  x300a0082: 'BeamDoseSpecificationPoint',
  x300a0084: 'BeamDose',
  x300a0086: 'BeamMeterset',
  x300a0088: 'BeamDosePointDepth',
  x300a0089: 'BeamDosePointEquivalentDepth',
  x300a008a: 'BeamDosePointSSD',
  x300a00a0: 'NumberOfBrachyApplicationSetups',
  x300a00a2: 'BrachyAppSetupDoseSpecPoint',
  x300a00a4: 'BrachyApplicationSetupDose',
  x300a00b0: 'BeamSequence',
  x300a00b2: 'TreatmentMachineName',
  x300a00b3: 'PrimaryDosimeterUnit',
  x300a00b4: 'SourceAxisDistance',
  x300a00b6: 'BeamLimitingDeviceSequence',
  x300a00b8: 'RTBeamLimitingDeviceType',
  x300a00ba: 'SourceToBeamLimitingDeviceDistance',
  x300a00bb: 'IsocenterToBeamLimitingDeviceDist',
  x300a00bc: 'NumberOfLeafJawPairs',
  x300a00be: 'LeafPositionBoundaries',
  x300a00c0: 'BeamNumber',
  x300a00c2: 'BeamName',
  x300a00c3: 'BeamDescription',
  x300a00c4: 'BeamType',
  x300a00c6: 'RadiationType',
  x300a00c7: 'HighDoseTechniqueType',
  x300a00c8: 'ReferenceImageNumber',
  x300a00ca: 'PlannedVerificationImageSequence',
  x300a00cc: 'ImagingDeviceSpecificAcqParams',
  x300a00ce: 'TreatmentDeliveryType',
  x300a00d0: 'NumberOfWedges',
  x300a00d1: 'WedgeSequence',
  x300a00d2: 'WedgeNumber',
  x300a00d3: 'WedgeType',
  x300a00d4: 'WedgeID',
  x300a00d5: 'WedgeAngle',
  x300a00d6: 'WedgeFactor',
  x300a00d7: 'TotalWedgeTrayWaterEquivThickness',
  x300a00d8: 'WedgeOrientation',
  x300a00d9: 'IsocenterToWedgeTrayDistance',
  x300a00da: 'SourceToWedgeTrayDistance',
  x300a00db: 'WedgeThinEdgePosition',
  x300a00dc: 'BolusID',
  x300a00dd: 'BolusDescription',
  x300a00e0: 'NumberOfCompensators',
  x300a00e1: 'MaterialID',
  x300a00e2: 'TotalCompensatorTrayFactor',
  x300a00e3: 'CompensatorSequence',
  x300a00e4: 'CompensatorNumber',
  x300a00e5: 'CompensatorID',
  x300a00e6: 'SourceToCompensatorTrayDistance',
  x300a00e7: 'CompensatorRows',
  x300a00e8: 'CompensatorColumns',
  x300a00e9: 'CompensatorPixelSpacing',
  x300a00ea: 'CompensatorPosition',
  x300a00eb: 'CompensatorTransmissionData',
  x300a00ec: 'CompensatorThicknessData',
  x300a00ed: 'NumberOfBoli',
  x300a00ee: 'CompensatorType',
  x300a00f0: 'NumberOfBlocks',
  x300a00f2: 'TotalBlockTrayFactor',
  x300a00f3: 'TotalBlockTrayWaterEquivThickness',
  x300a00f4: 'BlockSequence',
  x300a00f5: 'BlockTrayID',
  x300a00f6: 'SourceToBlockTrayDistance',
  x300a00f7: 'IsocenterToBlockTrayDistance',
  x300a00f8: 'BlockType',
  x300a00f9: 'AccessoryCode',
  x300a00fa: 'BlockDivergence',
  x300a00fb: 'BlockMountingPosition',
  x300a00fc: 'BlockNumber',
  x300a00fe: 'BlockName',
  x300a0100: 'BlockThickness',
  x300a0102: 'BlockTransmission',
  x300a0104: 'BlockNumberOfPoints',
  x300a0106: 'BlockData',
  x300a0107: 'ApplicatorSequence',
  x300a0108: 'ApplicatorID',
  x300a0109: 'ApplicatorType',
  x300a010a: 'ApplicatorDescription',
  x300a010c: 'CumulativeDoseReferenceCoefficient',
  x300a010e: 'FinalCumulativeMetersetWeight',
  x300a0110: 'NumberOfControlPoints',
  x300a0111: 'ControlPointSequence',
  x300a0112: 'ControlPointIndex',
  x300a0114: 'NominalBeamEnergy',
  x300a0115: 'DoseRateSet',
  x300a0116: 'WedgePositionSequence',
  x300a0118: 'WedgePosition',
  x300a011a: 'BeamLimitingDevicePositionSequence',
  x300a011c: 'LeafJawPositions',
  x300a011e: 'GantryAngle',
  x300a011f: 'GantryRotationDirection',
  x300a0120: 'BeamLimitingDeviceAngle',
  x300a0121: 'BeamLimitingDeviceRotateDirection',
  x300a0122: 'PatientSupportAngle',
  x300a0123: 'PatientSupportRotationDirection',
  x300a0124: 'TableTopEccentricAxisDistance',
  x300a0125: 'TableTopEccentricAngle',
  x300a0126: 'TableTopEccentricRotateDirection',
  x300a0128: 'TableTopVerticalPosition',
  x300a0129: 'TableTopLongitudinalPosition',
  x300a012a: 'TableTopLateralPosition',
  x300a012c: 'IsocenterPosition',
  x300a012e: 'SurfaceEntryPoint',
  x300a0130: 'SourceToSurfaceDistance',
  x300a0134: 'CumulativeMetersetWeight',
  x300a0140: 'TableTopPitchAngle',
  x300a0142: 'TableTopPitchRotationDirection',
  x300a0144: 'TableTopRollAngle',
  x300a0146: 'TableTopRollRotationDirection',
  x300a0148: 'HeadFixationAngle',
  x300a014a: 'GantryPitchAngle',
  x300a014c: 'GantryPitchRotationDirection',
  x300a014e: 'GantryPitchAngleTolerance',
  x300a0180: 'PatientSetupSequence',
  x300a0182: 'PatientSetupNumber',
  x300a0183: 'PatientSetupLabel',
  x300a0184: 'PatientAdditionalPosition',
  x300a0190: 'FixationDeviceSequence',
  x300a0192: 'FixationDeviceType',
  x300a0194: 'FixationDeviceLabel',
  x300a0196: 'FixationDeviceDescription',
  x300a0198: 'FixationDevicePosition',
  x300a0199: 'FixationDevicePitchAngle',
  x300a019a: 'FixationDeviceRollAngle',
  x300a01a0: 'ShieldingDeviceSequence',
  x300a01a2: 'ShieldingDeviceType',
  x300a01a4: 'ShieldingDeviceLabel',
  x300a01a6: 'ShieldingDeviceDescription',
  x300a01a8: 'ShieldingDevicePosition',
  x300a01b0: 'SetupTechnique',
  x300a01b2: 'SetupTechniqueDescription',
  x300a01b4: 'SetupDeviceSequence',
  x300a01b6: 'SetupDeviceType',
  x300a01b8: 'SetupDeviceLabel',
  x300a01ba: 'SetupDeviceDescription',
  x300a01bc: 'SetupDeviceParameter',
  x300a01d0: 'SetupReferenceDescription',
  x300a01d2: 'TableTopVerticalSetupDisplacement',
  x300a01d4: 'TableTopLongitudinalSetupDisplace',
  x300a01d6: 'TableTopLateralSetupDisplacement',
  x300a0200: 'BrachyTreatmentTechnique',
  x300a0202: 'BrachyTreatmentType',
  x300a0206: 'TreatmentMachineSequence',
  x300a0210: 'SourceSequence',
  x300a0212: 'SourceNumber',
  x300a0214: 'SourceType',
  x300a0216: 'SourceManufacturer',
  x300a0218: 'ActiveSourceDiameter',
  x300a021a: 'ActiveSourceLength',
  x300a0222: 'SourceEncapsulationNomThickness',
  x300a0224: 'SourceEncapsulationNomTransmission',
  x300a0226: 'SourceIsotopeName',
  x300a0228: 'SourceIsotopeHalfLife',
  x300a0229: 'SourceStrengthUnits',
  x300a022a: 'ReferenceAirKermaRate',
  x300a022b: 'SourceStrength',
  x300a022c: 'SourceStrengthReferenceDate',
  x300a022e: 'SourceStrengthReferenceTime',
  x300a0230: 'ApplicationSetupSequence',
  x300a0232: 'ApplicationSetupType',
  x300a0234: 'ApplicationSetupNumber',
  x300a0236: 'ApplicationSetupName',
  x300a0238: 'ApplicationSetupManufacturer',
  x300a0240: 'TemplateNumber',
  x300a0242: 'TemplateType',
  x300a0244: 'TemplateName',
  x300a0250: 'TotalReferenceAirKerma',
  x300a0260: 'BrachyAccessoryDeviceSequence',
  x300a0262: 'BrachyAccessoryDeviceNumber',
  x300a0263: 'BrachyAccessoryDeviceID',
  x300a0264: 'BrachyAccessoryDeviceType',
  x300a0266: 'BrachyAccessoryDeviceName',
  x300a026a: 'BrachyAccessoryDeviceNomThickness',
  x300a026c: 'BrachyAccessoryDevNomTransmission',
  x300a0280: 'ChannelSequence',
  x300a0282: 'ChannelNumber',
  x300a0284: 'ChannelLength',
  x300a0286: 'ChannelTotalTime',
  x300a0288: 'SourceMovementType',
  x300a028a: 'NumberOfPulses',
  x300a028c: 'PulseRepetitionInterval',
  x300a0290: 'SourceApplicatorNumber',
  x300a0291: 'SourceApplicatorID',
  x300a0292: 'SourceApplicatorType',
  x300a0294: 'SourceApplicatorName',
  x300a0296: 'SourceApplicatorLength',
  x300a0298: 'SourceApplicatorManufacturer',
  x300a029c: 'SourceApplicatorWallNomThickness',
  x300a029e: 'SourceApplicatorWallNomTrans',
  x300a02a0: 'SourceApplicatorStepSize',
  x300a02a2: 'TransferTubeNumber',
  x300a02a4: 'TransferTubeLength',
  x300a02b0: 'ChannelShieldSequence',
  x300a02b2: 'ChannelShieldNumber',
  x300a02b3: 'ChannelShieldID',
  x300a02b4: 'ChannelShieldName',
  x300a02b8: 'ChannelShieldNominalThickness',
  x300a02ba: 'ChannelShieldNominalTransmission',
  x300a02c8: 'FinalCumulativeTimeWeight',
  x300a02d0: 'BrachyControlPointSequence',
  x300a02d2: 'ControlPointRelativePosition',
  x300a02d4: 'ControlPoint3DPosition',
  x300a02d6: 'CumulativeTimeWeight',
  x300a02e0: 'CompensatorDivergence',
  x300a02e1: 'CompensatorMountingPosition',
  x300a02e2: 'SourceToCompensatorDistance',
  x300a02e3: 'TotalCompTrayWaterEquivThickness',
  x300a02e4: 'IsocenterToCompensatorTrayDistance',
  x300a02e5: 'CompensatorColumnOffset',
  x300a02e6: 'IsocenterToCompensatorDistances',
  x300a02e7: 'CompensatorRelStoppingPowerRatio',
  x300a02e8: 'CompensatorMillingToolDiameter',
  x300a02ea: 'IonRangeCompensatorSequence',
  x300a02eb: 'CompensatorDescription',
  x300a0302: 'RadiationMassNumber',
  x300a0304: 'RadiationAtomicNumber',
  x300a0306: 'RadiationChargeState',
  x300a0308: 'ScanMode',
  x300a030a: 'VirtualSourceAxisDistances',
  x300a030c: 'SnoutSequence',
  x300a030d: 'SnoutPosition',
  x300a030f: 'SnoutID',
  x300a0312: 'NumberOfRangeShifters',
  x300a0314: 'RangeShifterSequence',
  x300a0316: 'RangeShifterNumber',
  x300a0318: 'RangeShifterID',
  x300a0320: 'RangeShifterType',
  x300a0322: 'RangeShifterDescription',
  x300a0330: 'NumberOfLateralSpreadingDevices',
  x300a0332: 'LateralSpreadingDeviceSequence',
  x300a0334: 'LateralSpreadingDeviceNumber',
  x300a0336: 'LateralSpreadingDeviceID',
  x300a0338: 'LateralSpreadingDeviceType',
  x300a033a: 'LateralSpreadingDeviceDescription',
  x300a033c: 'LateralSpreadingDevWaterEquivThick',
  x300a0340: 'NumberOfRangeModulators',
  x300a0342: 'RangeModulatorSequence',
  x300a0344: 'RangeModulatorNumber',
  x300a0346: 'RangeModulatorID',
  x300a0348: 'RangeModulatorType',
  x300a034a: 'RangeModulatorDescription',
  x300a034c: 'BeamCurrentModulationID',
  x300a0350: 'PatientSupportType',
  x300a0352: 'PatientSupportID',
  x300a0354: 'PatientSupportAccessoryCode',
  x300a0356: 'FixationLightAzimuthalAngle',
  x300a0358: 'FixationLightPolarAngle',
  x300a035a: 'MetersetRate',
  x300a0360: 'RangeShifterSettingsSequence',
  x300a0362: 'RangeShifterSetting',
  x300a0364: 'IsocenterToRangeShifterDistance',
  x300a0366: 'RangeShifterWaterEquivThickness',
  x300a0370: 'LateralSpreadingDeviceSettingsSeq',
  x300a0372: 'LateralSpreadingDeviceSetting',
  x300a0374: 'IsocenterToLateralSpreadingDevDist',
  x300a0380: 'RangeModulatorSettingsSequence',
  x300a0382: 'RangeModulatorGatingStartValue',
  x300a0384: 'RangeModulatorGatingStopValue',
  x300a038a: 'IsocenterToRangeModulatorDistance',
  x300a0390: 'ScanSpotTuneID',
  x300a0392: 'NumberOfScanSpotPositions',
  x300a0394: 'ScanSpotPositionMap',
  x300a0396: 'ScanSpotMetersetWeights',
  x300a0398: 'ScanningSpotSize',
  x300a039a: 'NumberOfPaintings',
  x300a03a0: 'IonToleranceTableSequence',
  x300a03a2: 'IonBeamSequence',
  x300a03a4: 'IonBeamLimitingDeviceSequence',
  x300a03a6: 'IonBlockSequence',
  x300a03a8: 'IonControlPointSequence',
  x300a03aa: 'IonWedgeSequence',
  x300a03ac: 'IonWedgePositionSequence',
  x300a0401: 'ReferencedSetupImageSequence',
  x300a0402: 'SetupImageComment',
  x300a0410: 'MotionSynchronizationSequence',
  x300a0412: 'ControlPointOrientation',
  x300a0420: 'GeneralAccessorySequence',
  x300a0421: 'GeneralAccessoryID',
  x300a0422: 'GeneralAccessoryDescription',
  x300a0423: 'GeneralAccessoryType',
  x300a0424: 'GeneralAccessoryNumber',
  x300c0002: 'ReferencedRTPlanSequence',
  x300c0004: 'ReferencedBeamSequence',
  x300c0006: 'ReferencedBeamNumber',
  x300c0007: 'ReferencedReferenceImageNumber',
  x300c0008: 'StartCumulativeMetersetWeight',
  x300c0009: 'EndCumulativeMetersetWeight',
  x300c000a: 'ReferencedBrachyAppSetupSeq',
  x300c000c: 'ReferencedBrachyAppSetupNumber',
  x300c000e: 'ReferencedSourceNumber',
  x300c0020: 'ReferencedFractionGroupSequence',
  x300c0022: 'ReferencedFractionGroupNumber',
  x300c0040: 'ReferencedVerificationImageSeq',
  x300c0042: 'ReferencedReferenceImageSequence',
  x300c0050: 'ReferencedDoseReferenceSequence',
  x300c0051: 'ReferencedDoseReferenceNumber',
  x300c0055: 'BrachyReferencedDoseReferenceSeq',
  x300c0060: 'ReferencedStructureSetSequence',
  x300c006a: 'ReferencedPatientSetupNumber',
  x300c0080: 'ReferencedDoseSequence',
  x300c00a0: 'ReferencedToleranceTableNumber',
  x300c00b0: 'ReferencedBolusSequence',
  x300c00c0: 'ReferencedWedgeNumber',
  x300c00d0: 'ReferencedCompensatorNumber',
  x300c00e0: 'ReferencedBlockNumber',
  x300c00f0: 'ReferencedControlPointIndex',
  x300c00f2: 'ReferencedControlPointSequence',
  x300c00f4: 'ReferencedStartControlPointIndex',
  x300c00f6: 'ReferencedStopControlPointIndex',
  x300c0100: 'ReferencedRangeShifterNumber',
  x300c0102: 'ReferencedLateralSpreadingDevNum',
  x300c0104: 'ReferencedRangeModulatorNumber',
  x300e0002: 'ApprovalStatus',
  x300e0004: 'ReviewDate',
  x300e0005: 'ReviewTime',
  x300e0008: 'ReviewerName',
  x40000000: 'TextGroupLength',
  x40000010: 'Arbitrary',
  x40004000: 'TextComments',
  x40080040: 'ResultsID',
  x40080042: 'ResultsIDIssuer',
  x40080050: 'ReferencedInterpretationSequence',
  x40080100: 'InterpretationRecordedDate',
  x40080101: 'InterpretationRecordedTime',
  x40080102: 'InterpretationRecorder',
  x40080103: 'ReferenceToRecordedSound',
  x40080108: 'InterpretationTranscriptionDate',
  x40080109: 'InterpretationTranscriptionTime',
  x4008010a: 'InterpretationTranscriber',
  x4008010b: 'InterpretationText',
  x4008010c: 'InterpretationAuthor',
  x40080111: 'InterpretationApproverSequence',
  x40080112: 'InterpretationApprovalDate',
  x40080113: 'InterpretationApprovalTime',
  x40080114: 'PhysicianApprovingInterpretation',
  x40080115: 'InterpretationDiagnosisDescription',
  x40080117: 'InterpretationDiagnosisCodeSeq',
  x40080118: 'ResultsDistributionListSequence',
  x40080119: 'DistributionName',
  x4008011a: 'DistributionAddress',
  x40080200: 'InterpretationID',
  x40080202: 'InterpretationIDIssuer',
  x40080210: 'InterpretationTypeID',
  x40080212: 'InterpretationStatusID',
  x40080300: 'Impressions',
  x40084000: 'ResultsComments',
  x4ffe0001: 'MACParametersSequence',
  x50xx0005: 'CurveDimensions',
  x50xx0010: 'NumberOfPoints',
  x50xx0020: 'TypeOfData',
  x50xx0022: 'CurveDescription',
  x50xx0030: 'AxisUnits',
  x50xx0040: 'AxisLabels',
  x50xx0103: 'DataValueRepresentation',
  x50xx0104: 'MinimumCoordinateValue',
  x50xx0105: 'MaximumCoordinateValue',
  x50xx0106: 'CurveRange',
  x50xx0110: 'CurveDataDescriptor',
  x50xx0112: 'CoordinateStartValue',
  x50xx0114: 'CoordinateStepValue',
  x50xx1001: 'CurveActivationLayer',
  x50xx2000: 'AudioType',
  x50xx2002: 'AudioSampleFormat',
  x50xx2004: 'NumberOfChannels',
  x50xx2006: 'NumberOfSamples',
  x50xx2008: 'SampleRate',
  x50xx200a: 'TotalTime',
  x50xx200c: 'AudioSampleData',
  x50xx200e: 'AudioComments',
  x50xx2500: 'CurveLabel',
  x50xx2600: 'CurveReferencedOverlaySequence',
  x50xx2610: 'ReferencedOverlayGroup',
  x50xx3000: 'CurveData',
  x52009229: 'SharedFunctionalGroupsSequence',
  x52009230: 'PerFrameFunctionalGroupsSequence',
  x54000100: 'WaveformSequence',
  x54000110: 'ChannelMinimumValue',
  x54000112: 'ChannelMaximumValue',
  x54001004: 'WaveformBitsAllocated',
  x54001006: 'WaveformSampleInterpretation',
  x5400100a: 'WaveformPaddingValue',
  x54001010: 'WaveformData',
  x56000010: 'FirstOrderPhaseCorrectionAngle',
  x56000020: 'SpectroscopyData',
  x60000000: 'OverlayGroupLength',
  x60xx0010: 'OverlayRows',
  x60xx0011: 'OverlayColumns',
  x60xx0012: 'OverlayPlanes',
  x60xx0015: 'NumberOfFramesInOverlay',
  x60xx0022: 'OverlayDescription',
  x60xx0040: 'OverlayType',
  x60xx0045: 'OverlaySubtype',
  x60xx0050: 'OverlayOrigin',
  x60xx0051: 'ImageFrameOrigin',
  x60xx0052: 'OverlayPlaneOrigin',
  x60xx0060: 'OverlayCompressionCode',
  x60xx0061: 'OverlayCompressionOriginator',
  x60xx0062: 'OverlayCompressionLabel',
  x60xx0063: 'OverlayCompressionDescription',
  x60xx0066: 'OverlayCompressionStepPointers',
  x60xx0068: 'OverlayRepeatInterval',
  x60xx0069: 'OverlayBitsGrouped',
  x60xx0100: 'OverlayBitsAllocated',
  x60xx0102: 'OverlayBitPosition',
  x60xx0110: 'OverlayFormat',
  x60xx0200: 'OverlayLocation',
  x60xx0800: 'OverlayCodeLabel',
  x60xx0802: 'OverlayNumberOfTables',
  x60xx0803: 'OverlayCodeTableLocation',
  x60xx0804: 'OverlayBitsForCodeWord',
  x60xx1001: 'OverlayActivationLayer',
  x60xx1100: 'OverlayDescriptorGray',
  x60xx1101: 'OverlayDescriptorRed',
  x60xx1102: 'OverlayDescriptorGreen',
  x60xx1103: 'OverlayDescriptorBlue',
  x60xx1200: 'OverlaysGray',
  x60xx1201: 'OverlaysRed',
  x60xx1202: 'OverlaysGreen',
  x60xx1203: 'OverlaysBlue',
  x60xx1301: 'ROIArea',
  x60xx1302: 'ROIMean',
  x60xx1303: 'ROIStandardDeviation',
  x60xx1500: 'OverlayLabel',
  x60xx3000: 'OverlayData',
  x60xx4000: 'OverlayComments',
  x7fxx0000: 'PixelDataGroupLength',
  x7fxx0010: 'PixelData',
  x7fxx0011: 'VariableNextDataGroup',
  x7fxx0020: 'VariableCoefficientsSDVN',
  x7fxx0030: 'VariableCoefficientsSDHN',
  x7fxx0040: 'VariableCoefficientsSDDN',
  xfffafffa: 'DigitalSignaturesSequence',
  xfffcfffc: 'DataSetTrailingPadding',
  xfffee000: 'StartOfItem',
  xfffee00d: 'EndOfItems',
  xfffee0dd: 'EndOfSequence',
};

DICOMTagDescriptions.init(initialTagDescriptionMap);

// Discard original map...
initialTagDescriptionMap = null;

export { DICOMTagDescriptions };
